import React, { useCallback } from "react";
import BtnFullPreview from "./Buttons/BtnFullPreview";
import BtnFullNext from "./Buttons/BtnFullNext";
import BtnPreview from "./Buttons/BtnPreview";
import BtnNext from "./Buttons/BtnNext";
import InputSetItensPage from "./Buttons/InputSetItensPage";
import "./index.css";

export default function ControllerPage(props) {
  /**Função para ir para a última página */
  const handleFullPageUp = useCallback(
    (e) => {
      e.preventDefault();
      props.setPaginaAtual(props.paginas - 1);
    },
    [props]
  );
  /**Função para ir para a primeira página */
  const handleFullPageDown = useCallback(
    (e) => {
      e.preventDefault();
      props.setPaginaAtual(0);
    },
    [props]
  );
  /**Função para ir para a página anterior*/
  const handleChangePageDown = useCallback(
    (e) => {
      e.preventDefault();
      props.setPaginaAtual(
        props.paginaAtual > 0 ? Number(props.paginaAtual - 1) : 0
      );
    },
    [props]
  );
  /**Função para ir para a próxima página */
  const handleChangePageUP = useCallback(
    (e) => {
      e.preventDefault();
      props.setPaginaAtual(
        props.paginaAtual < props.paginas - 1
          ? Number(props.paginaAtual + 1)
          : props.paginaAtual
      );
    },
    [props]
  );
  /**Função para setar o número de itens por página */
  const handleSetItensPorPagina = useCallback(
    (itens) => {
      props.setItensPorPagina(Number(itens));
      localStorage.setItem("itensPorPagina", itens.toString());
    },
    [props]
  );

  return (
    <div className="grid grid-cols-3 text-center w-full">
      <div></div>
      <div className="flex  justify-center align-middle">
        {/* BTN_PREVIEW */}
        <div>
          <BtnFullPreview pageDown={handleFullPageDown} />
          <BtnPreview pageDown={handleChangePageDown} />
        </div>
        {/* iTENS/PAGINAS */}
        <div className="h-full flex align-items-center">
          {props.paginaAtual === 0 ? 1 : props.paginaAtual + 1} de{" "}
          {props.paginas}
        </div>
        {/* BTN_NEXT */}
        <div>
          <BtnNext pageUp={handleChangePageUP} />
          <BtnFullNext pageUp={handleFullPageUp} />
        </div>
      </div>
      {/* ITENS POR PAGINA */}
      <div className=" flex justify-content-center align-items-center">
        <InputSetItensPage
          itensPorPagina={props.itensPorPagina}
          SetItensPorPagina={handleSetItensPorPagina}
        />
      </div>
    </div>
  );
}
