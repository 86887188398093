import React, { useState } from "react";
import Logo from "../../assets/logo/eletrix5.png";
import SaltPassword from "../../services/md5";
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Image,
} from "@chakra-ui/react";
import Visivel from "../../assets/uteis/visivel.png";
import Invisivel from "../../assets/uteis/invisivel.png";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import CryptoJS from "crypto-js";
import "./styles.css";

export default function Entrar() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [loading, setLoading] = useState(false);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  function AcessarConta(e) {
    e.preventDefault();
    setSucesso("");
    setLoading(true);

    api
      .post("/v1/usuarios/login", {
        email,
        senha: SaltPassword(senha),
      })
      .then((res) => {
        if (res.data.tipo !== "A") {
          return alert("Você não tem permissão para acessar o painel");
        }

        localStorage.setItem("sessionToken", res.data.token);
        localStorage.setItem("sessionNome", res.data.nome);
        /*
        usando SaltPassword para que consiga ter os valores no localstorage do navegador 
        fazendo isso poderei manter os valores guardados mesmo se houver refresh da pág
        e se o usuario mudar esses valores manualmente, a aplicação não funcionará para 
        ele(funcionará apenas no dele, pois existe o TOKEN de usuário)
        (SERVE P/ CRIPTOGRAFIAS NO LOCALSTORAGE (AO USAR ESSES VALORES É PRECISO DESCRIPTOGRAFAR))
        */
        localStorage.setItem("sessionTipo", SaltPassword(`${res.data.tipo}`));
        localStorage.setItem(
          "sessionId",
          SaltPassword(`${res.data.id_usuario}`)
        );
        localStorage.setItem(
          "uid",
          CryptoJS.AES.encrypt(`${res.data.id_usuario}`, "ID_ADM").toString()
        );
        localStorage.setItem("sessionEmail", email);

        navigate("/painel-de-controle/carregadores");
      })
      .catch((error) => {
        setSucesso("N");
        setTimeout(() => setSucesso(""), 3000);
        localStorage.setItem("sessionEmail", email);
      });
    setLoading(false);
  }

  function Teclas(e) {
    switch (e.code) {
      case "NumpadEnter":
      case "Enter":
        AcessarConta(e);
        break;
      default:
        return null;
    }
  }

  return (
    <div className="container-fluid vh-100">
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="row">
          <div className="d-flex justify-content-center mb-5">
            <img src={Logo} className="img-entrar" alt="Logo eletrix" />
          </div>
          <div className="col-lg-8 offset-lg-2 col-8 offset-2">
            <form>
              <div className="mb-3">
                {/* Email do usuário */}
                <label htmlFor="InputEmail">E-mail: </label>
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={(e) => Teclas(e)}
                  value={email}
                  className="form-control"
                  placeholder="Digite seu Email"
                  id="inputEmail"
                  aria-describedby="Email"
                />
              </div>
              <div className="mb-3">
                {/* Senha do usuário */}
                <label htmlFor="inputSenha">Senha: </label>
                <InputGroup>
                  <Input
                    className="form-control"
                    onChange={(e) => setSenha(e.target.value)}
                    onKeyUp={(e) => Teclas(e)}
                    value={senha}
                    type={show ? "text" : "password"}
                    placeholder="Digite sua senha"
                    id="inputSenha"
                    aria-describedby="Senha"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      size="sm"
                      className="btn-visible mt-1"
                      onClick={handleClick}
                    >
                      {show ? (
                        <Image
                          src={Visivel}
                          width="100%"
                          boxSize="30px"
                          opacity={0.5}
                        />
                      ) : (
                        <Image
                          src={Invisivel}
                          width="100%"
                          boxSize="30px"
                          opacity={0.5}
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <button
                  type="button"
                  className="btn btn-lg btn-principal"
                  onClick={(e) => AcessarConta(e)}
                  disabled={loading}
                >
                  {loading ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      ></span>
                      <span className="ms-2">Enviando...</span>
                    </div>
                  ) : (
                    <span className="">Acessar</span>
                  )}
                </button>
              </div>
            </form>

            {sucesso === "N" ? (
              <div className="alert alert-danger mt-2" role="alert">
                {" "}
                Email ou senha incorretos
              </div>
            ) : null}

            <div className="row d-flex mt-4 text-center">
              <Link to="/recuperar-senha" className="link-recuperar-senha">
                Esqueci minha senha{" "}
              </Link>
            </div>
            <div className="row d-flex mt-4 text-center">
              <Link to="/cadastro" className="link-conta fw-bolder">
                Criar conta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
