/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import Codigo from '../inputs'
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api'
import SaltPassword from '../../../services/md5';

export default function formCodigo(props) {
    const email = props.email ? props.email : '';

    const navigate = useNavigate();
    const [codigo1, setCodigo1] = useState([])
    const [codigo2, setCodigo2] = useState([])
    const [codigo3, setCodigo3] = useState([])
    const [codigo4, setCodigo4] = useState([])
    const [erro, setError] = useState('')
    const [msg, setMsg] = useState('')

    const [loading, setLoading] = useState(false);

    function Codigo1(cod) { setCodigo1(cod) }
    function Codigo2(cod) { setCodigo2(cod) }
    function Codigo3(cod) { setCodigo3(cod) }
    function Codigo4(cod) { setCodigo4(cod) }

    function VerificarCodigo() {
        setLoading(true)
        setError('')
        let codigoFinal = `${codigo1}${codigo2}${codigo3}${codigo4}`

        switch (localStorage.getItem('sessionTipoCodigo')) {
            // R = recuperar senha
            // C = criar conta
            case "R":
                api.get(`v1/usuarios/esqueceusenha?email=${email}&codigo_senha=${codigoFinal}`)
                    .then(res => {
                        if (res.data.status === "ok") {
                            localStorage.setItem('sessionCode', codigoFinal)
                            navigate('/alterar-senha')
                        } else {
                            setError('Ocorreu um erro inesperado, tente novamente mais tarde')
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                        console.log(error)
                        setError('Ocorreu um erro, tente novamente')
                    })
                break;
            case "C":
                api.get(`/v1/usuarios/validaremail?email=${email}&codigo_email=${codigoFinal}`)
                    .then(res => {
                        if (res.data.status === "ok") {
                            api.post("/v1/usuarios/registro", {
                                nome: props.nome,
                                email,
                                telefone: props.telefone,
                                senha: SaltPassword(props.senha)
                            }).then(res => {
                                localStorage.setItem('sessionId', res.data.id_usuario)
                                localStorage.setItem('sessionToken', res.data.token)
                                // C = criar conta 
                                // R = recuperar senha
                                localStorage.removeItem('sessionTipoCodigo')
                                setMsg('Conta criada com sucesso')
                                setTimeout(() => setMsg(''), 700)
                                setTimeout(() => navigate('/'), 900)
                            }).catch(error => {
                                console.log(error)
                                setError('Ocorreu um erro inesperado, tente novamente mais tarde')
                                setTimeout(() => setMsg(''), 3000)
                            })
                        } else {
                            setError('Ocorreu um erro inesperado, tente novamente mais tarde')
                            setTimeout(() => setMsg(''), 3000)
                        }
                    })
                break
            default:
        }
    }

    return (
        <div className="d-flex justify-content-center align-content-center cod-div">
            <div className="col-lg-6">
                <div className="row text-center mt-2 mb-1">
                    <span>Digite o código enviado no email:</span>
                    <span className="fw-bold">{email}</span>
                </div>
                <div
                    className="d-flex justify-content-around mt-5 col-sm-8 offset-sm-2 offset-lg-0 col-lg-12 offset-md-0 col-md-12">
                    <Codigo Codigo1={Codigo1} Id="1" />
                    <Codigo Codigo2={Codigo2} Id="2" />
                    <Codigo Codigo3={Codigo3} Id="3" />
                    <Codigo Codigo4={Codigo4} Id="4" />
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <button
                        type="button"
                        className="btn btn-primary btn-lg btn-principal"
                        onClick={VerificarCodigo}
                        disabled={loading}
                    >
                        {loading ?
                            <div>
                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                <span className="ms-2">Enviando...</span>
                            </div> : <span className="">Próximo</span>}
                    </button>
                </div>
                {erro.length > 0 ? <div className="alert alert-danger mt-2" role="alert"> {erro}</div> : null}
                {msg.length > 0 ? <div className="alert alert-success mt-2" role="alert"> {msg}</div> : null}

            </div>
        </div>
    )
}
