import { isDisabled } from '@testing-library/user-event/dist/utils';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import api from '../../../../services/api'
import { useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '30px',
        transform: 'translate(-50%, -50%)',
    },
};

export default function ModalExcluir(props) {
    const [isDisabled, setIsDisebled] = useState(true)

    const navigate = useNavigate()

    function afterOpenModal() {
        setTimeout(() => { setIsDisebled(false) }, 2000)
    }
    function afterCloseModal() {
        setIsDisebled(true)
    }

    function handleExcluir(e) {
        e.preventDefault()
        api.delete(`v1/carregadores/${props.id_carregador}`, {
            token: localStorage.getItem('sessionToken')
        })
            .then(res => {
                alert('Carregador excluido com sucesso')
                navigate('/painel-de-controle/carregadores')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className='p-5'>
            <Modal
                isOpen={props.modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={props.closeModal}
                onAfterClose={afterCloseModal}
                style={customStyles}
                contentLabel="Modal excluir usuário"
            >
                <div className="text-center">
                    <h2>Excluir carregador?</h2>
                </div>
                <div className="mt-3 mb-2">Você tem certeza que deseja excluir esse carregador permanentemente?</div>
                {/* TODO: realizar verificação para excluir o usuário? */}
                <div className="row">
                    <button className="btn btn-danger mt-3 mb-3" onClick={(e) => handleExcluir(e)} disabled={isDisabled}>Excluir</button>
                    <button className="btn btn-success mb-1" onClick={props.closeModal}>Cancelar</button>
                </div>

            </Modal>
        </div>
    )
}
