import React from "react";

export default function ButonFilterIdCarregador({
  filterId,
  handleFiltrarID,
  handleLimparID,
  classButton,
}) {
  return (
    <div className="d-flex align-items-center me-1">
      {filterId === 0 ? (
        <button
          className={`${classButton} h-auto align-self-center btn btn-primary text-center mx-1 mt-1`}
          onClick={(e) => handleFiltrarID(e)}
        >
          Filtrar
        </button>
      ) : (
        <button
          className={`${classButton} h-auto align-self-center btn btn-primary text-center mx-1 mt-1`}
          onClick={(e) => handleLimparID(e)}
        >
          Limpar
        </button>
      )}
    </div>
  );
}
