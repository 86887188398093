/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Dock } from 'react-dock'
import './styles.css'
import api from './../../services/api';
import { Img } from '@chakra-ui/react';
import Vermelho from '../../assets/icones/carregadores_info/vermelho.webp'
import Amarelo from '../../assets/icones/carregadores_info/amarelo.webp'
import Verde from '../../assets/icones/carregadores_info/verde.webp'
import Plug from '../../assets/icones/carregadores_info/plugs.png'
import Potencia from '../../assets/icones/carregadores_info/potencia.png'
import Horario from '../../assets/icones/carregadores_info/horario.png'
import Telefone from '../../assets/icones/carregadores_info/telefone.png'
import Preco from '../../assets/icones/carregadores_info/preco.png'

function Sidebar(props) {
    const [carregador, setCarregador] = useState([]);

    function Redirect(e) {
        window.location.href = `/carregadores/${props.id}`
    }

    useEffect(() => {
        if (props.open === true) {
            api.get(`/v1/carregadores/${props.id}`).then(res => {
                setCarregador(res.data)
            }).catch(error => { console.log(error) })
        }
    }, [props.id, props.open]);

    function maskJs(value, pattern) {
        if (value) {
            let i = 0;
            const v = value.toString();
            return pattern.replace(/#/g, () => v[i++] || '');
        }

    };

    return (
        <Dock
            position='right'
            isVisible={props.open}
            size={window.screen.width < 800 ? 0.7 : 0.4}
            fluid={true}

            onVisibleChange={() => {
                props.openSidebar(false)
            }}
        >

            {carregador?.length > 0 ?
                <div className="container h-100 pt-4 sidebar">
                    <div className="row aling-itens-end footer h-100">
                        <h5>{carregador[0].nome_local}</h5>
                        {/* ENDEREÇO */}
                        <div className="col-12  align-itens-center">
                            <span>{carregador[0].endereco} - </span>
                            <span>{carregador[0].complemento} - </span>
                            <span>{carregador[0].bairro} - </span>
                            <span>{carregador[0].cidade} - </span>
                            <span>{carregador[0].uf},  {maskJs(carregador[0].cep, '#####-###')}</span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* STATUS */}
                            <span>
                                <Img
                                    src={
                                        carregador[0].status === "D" ? Verde :
                                            carregador[0].status === "U" ? Amarelo :
                                                Vermelho
                                    }
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Status:</span>
                            </span>
                            <span>
                                {
                                    carregador[0].status === "D" ? "Disponível" :
                                        carregador[0].status === "U" ? "Em uso" :
                                            "Indisponível"
                                }
                            </span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* PLUGS */}
                            <span>
                                <Img
                                    src={Plug}
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Plugs:</span>
                            </span>
                            <span>{carregador[0].plug}</span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* POTENCIA */}
                            <span>
                                <Img
                                    src={Potencia}
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Potência:</span>
                            </span>
                            <span>{carregador[0].potencia}</span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* HORARIOS */}
                            <span>
                                <Img
                                    src={Horario}
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Horários:</span>
                            </span>
                            <span>{carregador[0].horario}</span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* TELEFONE */}
                            <span>
                                <Img
                                    src={Telefone}
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Telefone:</span>
                            </span>
                            <span>
                                {maskJs(carregador[0].telefone, '(##) #####-####')}
                            </span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-itens-center">
                            {/* PREÇO */}
                            <span>
                                <Img
                                    src={Preco}
                                    height={15}
                                    className="mb-1" />
                                <span className='ms-2'>Preço:</span>
                            </span>
                            <span>R$ {carregador[0].preco} KwH</span>
                        </div>
                        <div className="col-12">
                            {/* EDITAR CARREGADOR */}
                            <button
                                className='btn btn-success w-100 mb-1'
                                onClick={(e) => { Redirect() }}
                            >
                                Editar carregador
                            </button>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </Dock >
    )
}

export default Sidebar;