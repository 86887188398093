import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Voltar from '../../assets/uteis/voltar.png'
import { Image } from '@chakra-ui/react'
import FormCodigo from '../../components/Digitar-codigo/form-codigo'

export default function DigitarCodigo() {
    const email = localStorage.getItem('sessionEmail');
    return (
        <div className="fluid">
            <Link to="/recuperar-senha">
                {
                    localStorage.getItem('sessionTipoCodigo') === 'R' ?
                        <Image src={Voltar} alt="icone para voltar" width="auto" height="70px" className="ms-2" />
                        : null
                }
            </Link>
            <FormCodigo
                email={email}
            />
        </div>
    )
}
