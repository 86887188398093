/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalExcluir from "../modal";
import { IMaskInput } from "react-imask";
import axios from "axios";
export default function Inputs(props) {
  /* 
    FIXME: onKeyUp e OnChange nos IMaskInput serve p/ que
    não de problema no celular ao se preencher os inputs
    (problema do input se apagar ao clicar em outros inputs)
    */
  const [valido, setValido] = useState(false);

  /* Validação de inputs */
  function isValidCidade() {
    return /^([a-zA-Zà-úÀ-Ú]|\s+)+$/.test(props.cidade);
  }
  function isValidUF() {
    return /^([a-zA-Zà-úÀ-Ú]|\s+)+$/.test(props.uf);
  }
  function isValidCEP() {
    return /^[0-9]{5}-[0-9]{3}$/.test(props.cep);
  }

  useEffect(() => {
    if (props.cep) {
      if (!isValidCEP(props.cep)) {
        document.getElementById("inputCep").classList.add("is-invalid");
      } else {
        document.getElementById("inputCep").classList.remove("is-invalid");
        document.getElementById("inputCep").classList.add("is-valid");
      }
    }
  }, [props.cep]);

  useEffect(() => {
    if (props.uf.length === 2) {
      if (!isValidUF(props.uf)) {
        document.getElementById("inputUf").classList.add("is-invalid");
      } else {
        document.getElementById("inputUf").classList.remove("is-invalid");
        document.getElementById("inputUf").classList.add("is-valid");
      }
    } else if (props.uf.length !== 2 && props.uf.length > 0) {
      document.getElementById("inputUf").classList.add("is-invalid");
    }
  }, [props.uf]);

  useEffect(() => {
    let tel = props.telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
    if (props.telefone) {
      if (tel.length !== 11 && tel.length !== 10) {
        document.getElementById("inputTelefone").classList.add("is-invalid");
      } else {
        document.getElementById("inputTelefone").classList.remove("is-invalid");
        document.getElementById("inputTelefone").classList.add("is-valid");
      }
    }
  }, [props.telefone]);

  useEffect(() => {
    if (props.cidade) {
      if (!isValidCidade(props.cidade)) {
        document.getElementById("inputCidade").classList.add("is-invalid");
      } else {
        document.getElementById("inputCidade").classList.remove("is-invalid");
        document.getElementById("inputCidade").classList.add("is-valid");
      }
    }
  }, [props.cidade]);

  useEffect(() => {
    /* tirando caracteres especiais */
    let tel = props.telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
    let cep = props.cep.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
    /* ---------------------------- */

    // if (
    //     cep.length === 8 &&
    //     props.endereco?.length > 0 &&
    //     props.bairro?.length > 0 &&
    //     props.cidade?.length > 0 &&
    //     props.uf?.length > 1 &&
    //     props.uf?.length < 3
    // ) {
    //     axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${props.bairro}${props.endereco}${props.cidade}${props.uf}${props.cep}&key=${process.env.REACT_APP_MAPS_KEY}`)
    //         .then(res => {
    //             // let teste = res;
    //             props.setLatitude(res.data.results[0].geometry.location.lat);
    //             props.setLongitude(res.data.results[0].geometry.location.lng);
    //             // console.log(teste)
    //         })
    //         .catch(error => console.log(error))
    // }

    if (
      /*
            as interrogações depois dos nomes das variáveis 
            servem para verificar se a variável existe,
            caso não existir ele vai ignorar o ".length"
            */
      (tel.length === 10 || tel.length === 11) &&
      cep.length === 8 &&
      props.local?.length > 0 &&
      props.endereco?.length > 0 &&
      props.bairro?.length > 0 &&
      props.cidade?.length > 0 &&
      props.uf?.length > 1 &&
      props.uf?.length < 3 &&
      props.privado?.length > 0 &&
      props.plug?.length > 0 &&
      props.ativo?.length > 0 &&
      props.potencia?.length > 0 &&
      props.horario?.length > 0 &&
      //props.preco.length > 0 &&
      props.status?.length > 0
    ) {
      /* 
            fazendo com que o botão fique ativo apenas quando 
            todas as informações estiverem preenchidos
            */
      setValido(true);
    } else {
      setValido(false);
    }
  }, [
    props.cep,
    props.local,
    props.endereco,
    props.telefone,
    props.bairro,
    props.cidade,
    props.uf,
    props.cep,
    props.privado,
    props.plug,
    props.ativo,
    props.potencia,
    props.horario,
    props.status,
    props.preco,
  ]);
  /* -Fim das validações- */

  return (
    <>
      <form>
        <div>
          <div className="row">
            <aside className="col-md-4 mb-1">
              {/* Local do carregador */}
              <label htmlFor="inputLocal" className="fw-bold">
                Local:{" "}
              </label>
              <input
                type="text"
                id="inputLocal"
                placeholder="Digite o local"
                value={props.local}
                onChange={(e) => props.setLocal(e.target.value)}
                className="form-control"
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Endereço do carregador */}
              <label htmlFor="inputEndereco" className="fw-bold">
                Endereco:{" "}
              </label>

              <input
                id="inputEndereco"
                placeholder="Digite o endereço"
                value={props.endereco}
                onChange={(e) => props.setEndereco(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Telefone */}
              <label htmlFor="inputTelefone" className="fw-bold">
                Telefone:{" "}
              </label>
              <IMaskInput
                id="inputTelefone"
                autoComplete="off"
                mask="(00) 00000-0000"
                placeholder="(XX) XXXXX-XXXX"
                value={props.telefone}
                className="form-control "
                onKeyUp={(e) => props.setTelefone(e.target.value)}
                onChange={(e) => props.setTelefone(e.target.value)}
                aria-describedby="Telefone"
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Complemento do local */}
              <label htmlFor="inputComplemento" className="fw-bold">
                Complemento:{" "}
              </label>

              <input
                id="inputComplemento"
                placeholder="Digite o complemento"
                value={props.complemento}
                onChange={(e) => props.setComplemento(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Bairro */}
              <label htmlFor="inputBairro" className="fw-bold">
                Bairro:{" "}
              </label>

              <input
                id="inputBairro"
                placeholder="Digite o bairro"
                value={props.bairro}
                onChange={(e) => props.setBairro(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Cidade */}
              <label htmlFor="inputCidade" className="fw-bold">
                Cidade:{" "}
              </label>

              <input
                id="inputCidade"
                placeholder="Digite a cidade"
                value={props.cidade}
                onChange={(e) => props.setCidade(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Estado */}
              <label htmlFor="inputUf" className="fw-bold">
                Estado:{" "}
              </label>

              <input
                id="inputUf"
                placeholder="Digite o estado"
                maxLength={2}
                value={props.uf.toUpperCase()}
                onChange={(e) => props.setUf(e.target.value.toUpperCase())}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* CEP */}
              <label htmlFor="inputCep" className="fw-bold">
                CEP:{" "}
              </label>

              <IMaskInput
                id="inputCep"
                mask="00000-000"
                placeholder="Digite o CEP"
                autoComplete="off"
                aria-describedby="Telefone"
                value={props.cep}
                onKeyUp={(e) => props.setCep(e.target.value)}
                onChange={(e) => props.setCep(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Carregador privado? (S/N) TODO: transformar em switch */}
              <label htmlFor="inputPrivado" className="fw-bold">
                Privado:{" "}
              </label>

              <input
                id="inputPrivado"
                placeholder="Carregador privado (S/N)"
                maxLength={1}
                value={props.privado.toUpperCase()}
                onChange={(e) => props.setPrivado(e.target.value.toUpperCase())}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Nome do plug */}
              <label htmlFor="inputPlug" className="fw-bold">
                Plug:{" "}
              </label>

              <input
                id="inputPlug"
                placeholder="Digite o nome do plug"
                value={props.plug}
                onChange={(e) => props.setPlug(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Carregador ativo? (S/N) TODO: transformar em switch */}
              <label htmlFor="inputAtivo" className="fw-bold">
                Ativo:{" "}
              </label>

              <input
                id="inputAtivo"
                placeholder="Carregador ativo (S/N)"
                maxLength={1}
                value={props.ativo.toUpperCase()}
                onChange={(e) => props.setAtivo(e.target.value.toUpperCase())}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4 mb-1">
              {/* Potência do carregador */}
              <label htmlFor="inputPotencia" className="fw-bold">
                Potência:{" "}
              </label>

              <input
                id="inputPotencia"
                placeholder="Digite a potencia"
                value={props.potencia ? props.potencia : ""}
                onChange={(e) => props.setPotencia(e.target.value)}
                className="form-control "
              />
            </aside>
            <aside className="col-md-4">
              {/* Horário de atividade do carregador */}
              <label htmlFor="inputHorario" className="fw-bold">
                Horário:{" "}
              </label>

              <input
                id="inputHorario"
                placeholder="Digite o horário de funcionamento"
                value={props.horario}
                onChange={(e) => props.setHorario(e.target.value)}
                className="form-control"
              />
            </aside>
            <aside className="col-md-4">
              {/* Status do carregador (D/U/M/P) */}
              <label htmlFor="inputStatus" className="fw-bold">
                Status:{" "}
              </label>
              <select
                className="form-select"
                onChange={(e) => props.setStatus(e.target.value)}
              >
                <option value="M" defaultValue>
                  {/* sem valor */}
                </option>
                <option
                  value="D"
                  selected={props.status === "D" ? true : false}
                >
                  Disponível
                </option>
                <option
                  value="U"
                  selected={
                    props.status === "U" || props.status === "P" ? true : false
                  }
                >
                  Em uso
                </option>
                <option
                  value="M"
                  selected={props.status === "M" ? true : false}
                >
                  Indisponível
                </option>
              </select>
            </aside>
            {/* PREÇO */}
            <aside className="col-md-4">
              <label htmlFor="inputPreco" className="fw-bold">
                Preço:{" "}
              </label>
              <input
                id="inputPreco"
                placeholder="Digite o preço"
                value={props.preco}
                onChange={(e) => props.setPreco(e.target.value)}
                className="form-control "
              />
            </aside>
            {/* LATITUDE */}
            <aside className="col-md-4">
              <label htmlFor="inputLatitude" className="fw-bold">
                Latitude:{" "}
              </label>
              <input
                id="inputLatitude"
                placeholder="Digite o preço"
                value={props.latitude}
                onChange={(e) => props.setLatitude(e.target.value)}
                className="form-control "
              />
            </aside>
            {/* LONGITUDE */}
            {/* troquei de lugar */}
            <aside className="col-md-4">
              <label htmlFor="inputLongitude" className="fw-bold">
                Longitude:{" "}
              </label>
              <input
                id="inputLongitude"
                placeholder="Digite o preço"
                value={props.longitude}
                onChange={(e) => props.setLongitude(e.target.value)}
                className="form-control"
              />
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 mb-2 text-center col-12 col-md-6 offset-md-3">
            {props.msg.length > 0 ? (
              <span className="alert alert-success">{props.msg}</span>
            ) : null}
          </div>
          <div className="mb-4 mt-4 col-8 offset-2 col-md-6 offset-md-3">
            <button
              className="btn btn-success w-100"
              onClick={(e) => {
                props.handleSalvar(e);
              }}
              disabled={!valido}
            >
              <span>Salvar</span>
            </button>
          </div>
          {props.handleExcluir ? (
            <div className="col-8 offset-2 col-md-6 offset-md-3">
              <button
                className="btn btn-danger w-100"
                onClick={(e) => {
                  props.handleExcluir(e);
                }}
              >
                <span>Excluir</span>
              </button>
            </div>
          ) : null}
        </div>
      </form>
      <ModalExcluir
        modalOpen={props.handleExcluir}
        closeModal={props.closeModal}
        modalIsOpen={props.modalIsOpen}
        id_carregador={props.id}
      />
    </>
  );
}
