/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { format } from "date-fns";
import Swal from "sweetalert2";
import api from "../../../services/api";
import useSWR from "swr";
import IconPDF from "../../../assets/icones/tabelas/pdf.webp";
import Pesquisa from "../../Paginacao/pesquisa";
import InputFilterDate from "../../Inputs/InputFilterDate";
import ButonFilterData from "../../Buttons/ButonFilterData";
import ButtonEdit from "./../../Buttons/ButtonEdit";
import "./styles.css";
import ControllerPage from "../../ControllerPage/ControllerPage";
import { Button } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import useFunction from "./utils/useFunctions";
import carregamentosPDF from "./utils/carregamentos";
import { ca } from "date-fns/locale";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};
export default function listarCarregamentos(props) {
  const { data } = useSWR(`/v1/carregamentos`, fetcher, {
    refreshInterval: 2500,
  });

  const [usuarios, setUsuarios] = useState([]);

  const [status, setStatus] = useState("T");
  const [tamanho, setTamanho] = useState(1000);
  const [pesquisa, setPesquisa] = useState("");
  const [tempo, setTempo] = useState(0);

  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(40);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const [paginas, setPaginas] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itensAtuais, setItensAtuais] = useState([]);

  const navigate = useNavigate();

  const [filterDateInicio, setFilterDateInicio] = useState("");
  const [filterDateFim, setFilterDateFim] = useState("");
  const [filterDate, setFilterDate] = useState(0);

  const filtro = "status";
  const local = "carregamentos";
  const padding = "p-0";
  const ISOData = `${new Date().toISOString().split("T")[0]}`;

  function handleEdit(id_carregamento) {
    navigate(`/carregamentos/${id_carregamento}`);
  }

  // function maskJs(value, pattern) {
  //     let i = 0;
  //     const v = value.toString();
  //     return pattern.replace(/#/g, () => v[i++] || '');
  // };

  /**Função para filtrar de acordo com a data
   * @param e mouse event
   */
  function handleFiltrar(e) {
    e.preventDefault();
    const listaStatus = itens
      .filter((itens) => itens.dt_cadastro.split(" ")[0] >= filterDateInicio)
      .filter((itens) => itens.dt_cadastro.split(" ")[0] <= filterDateFim)
      .filter((itens) => (status == "T" ? itens : itens.status == `${status}`));
    if (filterDateInicio && filterDateFim) {
      setItens(listaStatus);
      setPaginaAtual(0);
    }

    if (filterDateInicio !== "" || filterDateFim !== "") {
      setFilterDate(1);
    }

    if (filterDateInicio == "" || filterDateFim == "") {
      setFilterDate(0);
      Swal.fire({
        title: "Selecione uma data válida!",
        text: "Selecione um período válido para filtrar a lista.",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  }

  /**Função para limpar a data */
  function handleLimparData() {
    setFilterDateInicio("");
    setFilterDateFim("");
    setFilterDate(0);
    setItens(data);
    setPaginaAtual(0);
  }

  /*VER TAMANHO DA TABELA (RESPONSIVIDADE) E SETAR ITENS DE PAGINAÇÃO*/
  useEffect(() => {
    let tabela = document.getElementById("tabela").clientWidth;
    setTamanho(tabela);

    setItens(data);
    api.get(`/v1/usuarios`).then((res) => {
      let nome_usuario = res.data.map((usuario) => {
        return { id_usuario: usuario.id_usuario, nome: usuario.nome };
      });
      setUsuarios(nome_usuario);
    });
  }, []);

  // TAMANHO DO BOTÃO
  useEffect(() => {
    setTimeout(() => {
      let tabela = document.getElementById("tabela").clientWidth;
      if (tabela < 775) {
        setTamanho(tabela);
      }
    }, 400);
  }, [props.active]);

  const handleSetPagina = (pagina) => {
    setPaginaAtual(pagina);
  };
  const handleSetItensPorPagina = (itens) => {
    setItensPorPagina(itens);
  };

  const handleImprimirPDF = (e) => {
    let periodo = "";
    if (filterDateInicio && filterDateFim) {
      periodo =
        useFunction.formatarData(filterDateInicio) +
        " Até " +
        useFunction.formatarData(filterDateFim);
    } else {
      periodo = "Todos";
    }

    let filtro =
      status == "E"
        ? "Erro"
        : status == "A"
        ? "Aprovado"
        : status == "C"
        ? "Cancelado"
        : status == "N"
        ? "Negado"
        : "Todos";

    let soma = 0;
    let qtd = 0;
    let energia_total = 0;
    let valor = 0;
    let participacao = 0;
    let somaParticipacao = 0;
    let totalCusto = 0;
    // Id carregamento
    // Data
    // Id Carregador
    // Nome do carregador
    // Energia carregada (substituir ponto por vírgula)(soma total)
    // Custo
    // Custo x energia carregada (total)(soma total)

    let ListaFinal = itens.map((carregamento) => {
      qtd += 1;
      energia_total += carregamento.energia_carregada;
      participacao =
        ((Number(carregamento.preco - carregamento.custo) *
          Number(carregamento.energia_carregada)) /
          100) *
        10;
      valor =
        Number(carregamento.energia_carregada * carregamento.custo) +
        Number(participacao);

      soma += valor;
      somaParticipacao += Number(participacao);
      totalCusto += Number(carregamento.energia_carregada * carregamento.custo);

      return {
        id_carregamento: carregamento.id_carregamento,
        dt_cadastro: carregamento.dt_cadastro,
        id_carregador: carregamento.id_carregador,
        nome_local: carregamento.nome_local,
        energia_carregada: carregamento.energia_carregada,
        custo: Number(carregamento.energia_carregada * carregamento.custo),
        valor: valor,
        participacao: participacao,
      };
    });
    carregamentosPDF(
      ListaFinal,
      filtro,
      periodo,
      soma,
      qtd,
      energia_total,
      somaParticipacao,
      totalCusto
    );
  };

  // PAGINAÇÃO
  useEffect(() => {
    if (itens) {
      setStartIndex(paginaAtual * itensPorPagina);
      setEndIndex(startIndex + itensPorPagina);

      if (pesquisa !== "" && status === "T") {
        api
          .get(`/v1/${local}/pesquisa/${pesquisa}`)
          .then((res) => {
            if (
              filterDateInicio &&
              filterDateFim &&
              filterDateInicio !== "" &&
              filterDateFim !== ""
            ) {
              let itensFiltrado = res.data
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] >= filterDateInicio
                )
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] <= filterDateFim
                );

              setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
              setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
            } else {
              setItensAtuais(res.data.slice(startIndex, endIndex));
              setPaginas(Math.ceil(res.data.length / itensPorPagina));
            }
          })
          .catch((error) => console.log(error));
      }

      if (pesquisa !== "" && status !== "T") {
        api
          .get(`/v1/${local}/pesquisa/${pesquisa}`)
          .then((res) => {
            if (
              filterDateInicio &&
              filterDateFim &&
              filterDateInicio !== "" &&
              filterDateFim !== ""
            ) {
              let itensFiltrado = res.data
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] >= filterDateInicio
                )
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] <= filterDateFim
                )
                .filter((itens) => itens[filtro] === status);

              setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
              setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
            } else {
              let itensFiltrado = res.data?.filter(
                (itens) => itens[filtro] === status
              );
              setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
              setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
            }
          })
          .catch((error) => console.log(error));
      }

      if (pesquisa === "" && status === "T") {
        if (
          filterDateInicio &&
          filterDateFim &&
          filterDateInicio !== "" &&
          filterDateFim !== ""
        ) {
          let itensFiltrado = data
            .filter(
              (itens) => itens.dt_cadastro.split(" ")[0] >= filterDateInicio
            )
            .filter(
              (itens) => itens.dt_cadastro.split(" ")[0] <= filterDateFim
            );

          setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
          setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
        } else {
          setItensAtuais(data?.slice(startIndex, endIndex));
          setPaginas(Math.ceil(data?.length / itensPorPagina));
        }
      }

      if (pesquisa === "" && status !== "T") {
        if (
          filterDateInicio &&
          filterDateFim &&
          filterDateInicio !== "" &&
          filterDateFim !== ""
        ) {
          let itensFiltrado = data
            .filter(
              (itens) => itens.dt_cadastro.split(" ")[0] >= filterDateInicio
            )
            .filter((itens) => itens.dt_cadastro.split(" ")[0] <= filterDateFim)
            .filter((itens) => itens[filtro] === status);

          setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
          setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
        } else {
          let itensFiltrado = data?.filter((itens) => itens[filtro] === status);
          setItensAtuais(itensFiltrado.slice(startIndex, endIndex));
          setPaginas(Math.ceil(itensFiltrado.length / itensPorPagina));
        }
      }
    }
  }, [
    data,
    itens,
    endIndex,
    itensPorPagina,
    paginaAtual,
    startIndex,
    filterDate,
  ]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
  return (
    <div className="container-fluid w-100">
      <Box
        className={
          tamanho < 775 ? "d-block" : "d-flex justify-content-between w-100"
        }
        width={tamanho < 775 ? tamanho : "auto"}
      >
        <div className="d-flex align-content-center me-1">
          <Button
            className="btn btn-danger align-self-center"
            variant="solid"
            onClick={(e) => {
              handleImprimirPDF(e);
            }}
          >
            <Image src={IconPDF} width={20} className="me-2" />
            Gerar pdf
          </Button>
        </div>

        {tamanho > 775 ? (
          <div className="mb-2 d-flex">
            <InputFilterDate
              label="De"
              filterDate={filterDateInicio}
              setFilterDate={setFilterDateInicio}
              dataAtual={ISOData}
              processDate={setFilterDateInicio}
              minData={""}
            />
            <InputFilterDate
              label="Até"
              filterDate={filterDateFim}
              setFilterDate={setFilterDateInicio}
              dataAtual={ISOData}
              minData={""}
              setDataInicio={setFilterDateFim}
              processDate={setFilterDateFim}
            />
            <ButonFilterData
              filterDate={filterDate}
              handleFiltrar={handleFiltrar}
              handleLimparData={handleLimparData}
            />
            <div className="d-flex me-1">
              <select
                className="form-select me-3 align-self-center"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="T" defaultValue>
                  Todos
                </option>
                <option value="A">Ativos</option>
                <option value="F">Fechados</option>
              </select>
            </div>
            {/* pesquisa p/ pc */}
            <div className="d-flex me-1">
              <Pesquisa
                filtro={filtro}
                local={local}
                parametro={status}
                setPesquisa={setPesquisa}
                setItens={setItens}
                setPaginaAtual={setPaginaAtual}
                PesquisaAtual={pesquisa}
                tamanho={tamanho}
                setTempo={setTempo}
                className="h-50 align-self-center"
                dataInicio={filterDateInicio}
                dataFim={filterDateFim}
              />
            </div>
          </div>
        ) : (
          <Box
            className="text-center mb-2"
            width={tamanho < 775 ? tamanho : "auto"}
          >
            <div className="w-px-200">
              <InputFilterDate
                label="De"
                filterDate={filterDateInicio}
                setFilterDate={setFilterDateInicio}
                dataAtual={ISOData}
                processDate={setFilterDateInicio}
                minData={""}
                classInput="w-10"
              />
            </div>
            <div className="w-px-200">
              <InputFilterDate
                label="Até"
                filterDate={filterDateFim}
                setFilterDate={setFilterDateInicio}
                dataAtual={ISOData}
                minData={""}
                setDataInicio={setFilterDateFim}
                processDate={setFilterDateFim}
              />
            </div>
            <ButonFilterData
              filterDate={filterDate}
              handleFiltrar={handleFiltrar}
              handleLimparData={handleLimparData}
              classButton="mb-2"
            />
            <select
              className={`form-select mb-2 me-4 ${
                document.body.clientWidth < 775 ? "w-px-200" : "w-100"
              }`}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="T" defaultValue>
                Todos
              </option>
              <option value="A">Ativos</option>
              <option value="F">Fechados</option>
            </select>
            {/* pesquisa p/ celular */}
            <Pesquisa
              filtro={filtro}
              local={local}
              parametro={status}
              setPesquisa={setPesquisa}
              setItens={setItens}
              setPaginaAtual={setPaginaAtual}
              PesquisaAtual={pesquisa}
              tamanho={tamanho}
              setTempo={setTempo}
              dataInicio={filterDateInicio}
              dataFim={filterDateFim}
            />
          </Box>
        )}
      </Box>
      <table
        className="table table-bordered text-center align-middle"
        id="tabela"
      >
        <thead>
          <tr>
            <th className={`w-5`}>Id</th>
            <th className={`w-20`}>Data</th>
            <th className={`w-5`}>Id usuário</th>
            <th className={`w-15`}>Usuário</th>
            <th className={`w-5`}>Carregador</th>
            <th className={`w-15`}>Nome</th>
            <th className={`text-sm`}>Energia Carregada</th>
            <th className={`w-10`}>Duração</th>
            <th className={`w-5`}>Valor</th>
            <th className={`w-10`}>Status</th>
            <th className={`w-15`}>Endereço</th>
            <th className={``}>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          {itensAtuais?.map((carregamento) => {
            return (
              <tr
                key={carregamento.id_carregamento}
                className={`${
                  carregamento.status == "A"
                    ? "table-warning"
                    : carregamento.status == "F"
                    ? "table-success"
                    : ""
                } table-hover`}
              >
                <th className={`${padding}`}>{carregamento.id_carregamento}</th>
                <td className={`${padding} text-sm`}>
                  {format(
                    new Date(carregamento.dt_cadastro),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                </td>
                <td className={`${padding}`}>{carregamento.id_usuario}</td>
                <td className={`${padding}`}>
                  {usuarios.map((usuarios) => {
                    if (usuarios.id_usuario == carregamento.id_usuario) {
                      return usuarios.nome;
                    }
                  })}
                </td>

                <td className={`${padding}`}>{carregamento.id_carregador}</td>
                <td className={`${padding}`}>{carregamento.nome_local}</td>
                <td className={`${padding}`}>
                  {carregamento.energia_carregada.toString().replace(".", ",")}
                </td>
                <td className={`${padding}`}>{carregamento.duracao}</td>
                <td className={`${padding}`}>
                  {" "}
                  {/* FIXME: diferença entre valor e preço? */}
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(carregamento.valor)}
                </td>
                {/* <td>{maskJs(carregamento.telefone, '(##) #####-####')}</td> */}
                <td className={`${padding}`}>
                  {carregamento.status == "A" ? "Ativo" : ""}
                  {carregamento.status == "F" ? "Fechado" : ""}
                </td>
                <td className={`${padding}`}>{carregamento.endereco}</td>
                <td className={`${padding}`}>
                  <ButtonEdit
                    editar={carregamento.id_carregamento}
                    handleEdit={handleEdit}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ControllerPage
        paginaAtual={paginaAtual}
        setPaginaAtual={handleSetPagina}
        paginas={paginas}
        itensPorPagina={itensPorPagina}
        setItensPorPagina={handleSetItensPorPagina}
      />
    </div>
  );
}
