import React from "react";
import InputLabel from "./InputLabel";
import InputData from "./InputData";

export default function InputFilterDate({
  filterDate,
  setFilterDate,
  dataAtual,
  label,
  processDate,
  classInput,
}) {
  return (
    <div className="me-1">
      <InputLabel
        className="form-check-label me-2 align-self-center"
        text={`${label}`}
      />
      <InputData
        setDataInicio={setFilterDate}
        filterDate={filterDate}
        dataAtual={dataAtual}
        minData={""}
        processDate={processDate}
        className={`${classInput}`}
      />
    </div>
  );
}
