/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import SaltPassword from "./../../../services/md5";
import CryptoJS from "crypto-js";
import Paginacao from "./../../Paginacao/paginacao";
import { Box } from "@chakra-ui/react";
import Pesquisa from "../../Paginacao/pesquisa";
import format from "date-fns/format";
import ButtonEdit from "./../../Buttons/ButtonEdit";
import ControllerPage from "./../../ControllerPage/ControllerPage";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};

export default function listarUsuarios(props) {
  var bytes = CryptoJS.AES.decrypt(localStorage.getItem("uid"), "ID_ADM");
  var idNumber = bytes.toString(CryptoJS.enc.Utf8);

  const { data } = useSWR(`/v1/usuarios`, fetcher, { refreshInterval: 2200 });
  const { data: adm } = useSWR(`/v1/usuarios/` + idNumber, fetcher, {
    refreshInterval: 1000,
  });
  const [tamanho, setTamanho] = useState(1000);
  const [pesquisa, setPesquisa] = useState("");
  const [tempo, setTempo] = useState(0);

  const [tipo, setTipo] = useState("T");

  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const [paginas, setPaginas] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itensAtuais, setItensAtuais] = useState([]);

  const navigate = useNavigate();

  const filtro = "tipo";
  const local = "usuarios";
  const padding = "p-0";

  function handleEdit(id_usuario) {
    navigate(`/perfil/${id_usuario}`);
  }
  function maskJs(value, pattern) {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, () => v[i++] || "");
  }

  const handleSetPagina = (pagina) => {
    setPaginaAtual(pagina);
  };
  const handleSetItensPorPagina = (itens) => {
    setItensPorPagina(itens);
  };

  useEffect(() => {
    let tabela = document.getElementById("tabela").clientWidth;
    setTamanho(tabela);

    setItens(data);
  }, []);

  useEffect(() => {
    if (adm) {
      /*
            FIXME: SaltPassword usado para os dados poderem ser guardados no 
            localstorage com segurança
            */
      localStorage.setItem("sessionTipo", SaltPassword(`${adm[0].tipo}`));
    }
  }, [adm]);

  // // ATUALIZAR TABELAS EM TEMPO REAL(PAGINAÇÃO)
  useEffect(() => {
    if (pesquisa == "" && tipo == "T") {
      setItens(data);
    }
    if (pesquisa == "" && tipo !== "T") {
      setItens(data.filter((itens) => itens[filtro] === tipo));
    }
    if (pesquisa !== "" && tipo == "T") {
      api
        .get(`/v1/${local}/pesquisa/${pesquisa}`)
        .then((res) => {
          setItens(res.data);
        })
        .catch((error) => console.log(error));
    }
    if (pesquisa !== "" && tipo !== "T") {
      api
        .get(`/v1/${local}/pesquisa/${pesquisa}`)
        .then((res) => {
          setItens(res.data.filter((itens) => itens[filtro] === tipo));
        })
        .catch((error) => console.log(error));
    }
  }, [data]);

  // PAGINAÇÃO
  useEffect(() => {
    if (itens) {
      setPaginas(Math.ceil(itens?.length / itensPorPagina));
      setStartIndex(paginaAtual * itensPorPagina);
      setEndIndex(startIndex + itensPorPagina);

      if (pesquisa !== "") {
        if (tempo === 0) {
          //não ficar refazendo a requisição
        } else {
          if (tipo === "T") {
            api
              .get(`/v1/${local}/pesquisa/${pesquisa}`)
              .then((res) => {
                setItensAtuais(res.data.slice(startIndex, endIndex));
                setPaginaAtual(0);
                setTempo(0);
              })
              .catch((error) => console.log(error));
          } else {
            api
              .get(`/v1/${local}/pesquisa/${pesquisa}`)
              .then((res) => {
                setItensAtuais(
                  res.data
                    .filter((itens) => itens[filtro] === tipo)
                    .slice(startIndex, endIndex)
                );
                setPaginaAtual(0);
                setTempo(0);
              })
              .catch((error) => console.log(error));
          }
        }
      } else {
        if (tipo === "T") {
          setItensAtuais(data?.slice(startIndex, endIndex));
        } else {
          setItensAtuais(
            data
              ?.filter((itens) => itens[filtro] === tipo)
              .slice(startIndex, endIndex)
          );
        }
      }

      setItensAtuais(itens.slice(startIndex, endIndex));
    } else {
      setItens(data);
    }
  }, [data, endIndex, itens, itensPorPagina, paginaAtual, startIndex]);

  return (
    <div className="container-fluid w-100">
      <Box
        className={
          tamanho < 775 ? "d-block" : "d-flex justify-content-between w-100"
        }
        width={tamanho < 775 ? tamanho : "auto"}
      >
        <div></div>
        {tamanho > 775 ? (
          <div className="mb-2 d-flex">
            <div className="d-flex me-1">
              <select
                className="form-select me-3 align-self-center"
                onChange={(e) => setTipo(e.target.value)}
              >
                <option value="T" defaultValue>
                  Todos
                </option>
                <option value="A">Admin</option>
                <option value="C">Comum</option>
              </select>
            </div>

            <div className="d-flex me-1">
              {/* pesquisa p/ pc */}
              <Pesquisa
                filtro="tipo"
                local="usuarios"
                parametro={tipo}
                setPesquisa={setPesquisa}
                setItens={setItens}
                setPaginaAtual={setPaginaAtual}
                PesquisaAtual={pesquisa}
                tamanho={tamanho}
                setTempo={setTempo}
                dataInicio=""
                dataFim=""
              />
            </div>
          </div>
        ) : (
          <Box
            className="text-center mb-2"
            width={tamanho < 775 ? tamanho : "auto"}
          >
            <select
              className={`form-select mb-2 me-3 ${
                document.body.clientWidth < 775 ? "w-px-200" : "w-25"
              }`}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value="T" defaultValue>
                Todos
              </option>
              <option value="A">Admin</option>
              <option value="C">Comum</option>
            </select>
            {/* pesquisa p/ celular TODO: ARRUMAR*/}
            <Pesquisa
              filtro="tipo"
              local="usuarios"
              parametro={tipo}
              setPesquisa={setPesquisa}
              setItens={setItens}
              setPaginaAtual={setPaginaAtual}
              PesquisaAtual={pesquisa}
              tamanho={tamanho}
              setTempo={setTempo}
              dataInicio=""
              dataFim=""
            />
          </Box>
        )}
      </Box>

      <table
        className="table table-striped table-bordered text-center align-middle"
        id="tabela"
      >
        <thead>
          <tr>
            <th className={`w-5`}>Id</th>
            <th className={`w-15`}>Cadastro</th>
            <th className={`w-15`}>Nome</th>
            <th className={`w-25`}>Email</th>
            <th className={`w-15`}>Telefone</th>
            <th className={`w-10`}>Tipo</th>
            <th className={`w-10`}>Ativo</th>
            <th className={`w-5`}>Editar</th>
          </tr>
        </thead>
        <tbody>
          {itensAtuais?.length > 0
            ? itensAtuais.map((usuarios) => {
                return (
                  <tr key={usuarios.id_usuario} className="table-hover">
                    <td className={`${padding}`}>{usuarios.id_usuario}</td>
                    <td className={`${padding}`}>
                      {format(
                        new Date(usuarios.dt_cadastro),
                        "dd/MM/yyyy HH:mm"
                      )}
                    </td>
                    <td className={`${padding}`}>{usuarios.nome}</td>
                    <td className={`${padding}`}>{usuarios.email}</td>
                    <td className={`${padding} `}>
                      {maskJs(usuarios.telefone, "(##) #####-####")}
                    </td>
                    <td className={`${padding}`}>
                      {usuarios.tipo == "A" ? "Admin" : ""}
                      {usuarios.tipo == "C" ? "Comum" : ""}
                    </td>
                    <td className={`${padding}`}>
                      {usuarios.ativo == "S" ? "Sim" : ""}
                      {usuarios.ativo == "N" ? "Não" : ""}
                    </td>
                    <td className={`${padding}`}>
                      <ButtonEdit
                        editar={usuarios.id_usuario}
                        handleEdit={handleEdit}
                      />
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      <ControllerPage
        paginaAtual={paginaAtual}
        setPaginaAtual={handleSetPagina}
        paginas={paginas}
        itensPorPagina={itensPorPagina}
        setItensPorPagina={handleSetItensPorPagina}
      />
    </div>
  );
}
