import React, { useCallback, useEffect, useState } from 'react'
import { NumberInput, NumberInputField } from '@chakra-ui/react'
import './styles.css'

export default function DigitarCodigo(props) {
  const [codigo, setCodigo] = useState(0);

  function Codigo() {
    if (props.Codigo1) {
      props.Codigo1(codigo)
    }
    if (props.Codigo2) {
      props.Codigo2(codigo)
    }
    if (props.Codigo3) {
      props.Codigo3(codigo)
    }
    if (props.Codigo4) {
      props.Codigo4(codigo)
    }
  }

  //Ao se apertar uma tecla, o foco do input vai para o próximo
  function Teclas(e) {
    switch (e.code) {
      case "Digit1": case "Digit2": case "Digit3":
      case "Digit4": case "Digit5": case "Digit6":
      case "Digit7": case "Digit8": case "Digit9":
      case "Digit0": case "NumpadSubtract":
      case "Minus":
        if (parseInt(props.Id) > 0 && parseInt(props.Id) < 4) {
          document.getElementById(`${parseInt(props.Id) + 1}`).focus();
        }
        if (parseInt(props.Id) === 4) {
          document.getElementById(`${parseInt(props.Id)}`).blur();
        }
        break;
      case "Numpad1": case "Numpad2": case "Numpad3":
      case "Numpad4": case "Numpad5": case "Numpad6":
      case "Numpad7": case "Numpad8": case "Numpad9":
      case "Numpad0":
        if (parseInt(props.Id) > 0 && parseInt(props.Id) < 4) {
          document.getElementById(`${parseInt(props.Id) + 1}`).focus();
        }
        if (parseInt(props.Id) === 4) {
          document.getElementById(`${parseInt(props.Id)}`).blur();
        }
        break
      default:
    }
  }

  useEffect(() => {
     Codigo()
  }, [codigo])
  
  return (
    <>
      <form autoComplete="off">
        <NumberInput max={9} min={0} className="form-input">
          <NumberInputField
            className="form-codigo"
            autoComplete={false}
            maxLength={1}
            id={props.Id}
            onKeyUp={(e) => Teclas(e)}
            onBlur={(e) => {
              e.target.value !== '' ? setCodigo((e.target.value).replace(/\D/g, "0")) : setCodigo(0)
            }}
          />
        </NumberInput>
      </form>
    </>
  )
}
