import React from 'react'
import MenuLateral from '../../../components/Menu-lateral'

export default function PainelMapa() {
    return (
        <>
            <MenuLateral item3 />
        </>
    )
}
