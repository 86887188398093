import React from "react";

export default function ButonFilterData({
  filterDate,
  handleFiltrar,
  handleLimparData,
  classButton,
}) {
  return (
    <div className="d-flex align-items-center me-1">
      {filterDate === 0 ? (
        <button
          className={`${classButton} h-auto align-self-center btn btn-primary text-center mx-1 mt-1`}
          onClick={(e) => handleFiltrar(e)}
        >
          Filtrar
        </button>
      ) : (
        <button
          className={`${classButton} h-auto align-self-center btn btn-primary text-center mx-1 mt-1`}
          onClick={(e) => handleLimparData(e)}
        >
          Limpar
        </button>
      )}
    </div>
  );
}
