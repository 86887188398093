/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Voltar from '../../assets/uteis/voltar.png'
import EditarCarregador from '../../components/Carregadores/editar-carregadores'

export default function Carregadores() {
    const navigate = useNavigate()
    let { id } = useParams();

    return (
        <div className='overflow-auto overflow-md-hidden vh-100'>
            <div className="d-flex justify-content-between m-2">
                <div className="col-3">
                    <Link to="#">
                        <Image src={Voltar} alt="" height="10vh" onClick={() => navigate(-1)} />
                    </Link>
                </div>

                <div className="col-6 text-center">
                    <h1>Editar Carregador</h1>
                </div>

                <div className="col-3"></div>

            </div>

            <div className='text-center'>
                <button
                    className="btn btn-primary w-50 w-md-25 mb-4 text-center"
                    onClick={(e) => navigate(`/painel-de-controle/mapa/${id}`)}>
                    Ver no Mapa
                </button>
            </div>

            <div className='m-4'>
                    <EditarCarregador />
                </div>
            {/* {editar ?
                <div className='m-4'>
                    <EditarCarregador />
                </div>
                :
                <div className='m-4'>
                    <VisualizarCarregador />
                </div>
            } */}
        </div>
    )
}
