/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import Voltar from '../../assets/uteis/voltar.png'
import { Link } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import VisualizarCarregamentos from '../../components/Carregamentos/visualizar-carregamentos';
import { useNavigate } from 'react-router-dom';

export default function carregamentos(props) {
    const navigate = useNavigate()

    return (
        <div className='overflow-auto overflow-md-hidden vh-100'>
            <div className="d-flex justify-content-between m-2">
                <div className="col-3">
                    <Link to="#">
                        <Image src={Voltar} alt="" height="10vh" onClick={() => navigate(-1)} />
                    </Link>
                </div>

                <div className="col-6 text-center">
                    <h1>Visualizar Carregamento</h1>
                </div>

                <div className="col-3"></div>

            </div>

            {/* <div className='text-center'>
                <button className="btn btn-primary w-50 w-md-25 mb-4 text-center" onClick={(e) => handleEditar(e)}>{editar ? 'Visualizar' : 'Editar'}</button>
            </div> */}
            <div className='m-4'>
                <VisualizarCarregamentos />
            </div>
            {/* {editar ?
                <div className='m-4'>
                    <EditarCarregador />
                </div>
                :
                <div className='m-4'>
                    <VisualizarCarregador />
                </div>
            } */}
        </div>
    )
}
