import React, { useState } from 'react'
import Modal from 'react-modal'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom';
import SaltPassword from '../../../services/md5';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '30px',
        transform: 'translate(-50%, -50%)',
    },
};

export default function ModalExcluir(props) {
    const [isDisabled, setIsDisebled] = useState(true)

    const navigate = useNavigate()

    function afterOpenModal() {
        setTimeout(() => { setIsDisebled(false) }, 1500)
        // console.log(props.tipo)
        // console.log(SaltPassword("A"))
    }
    function afterCloseModal() {
        setIsDisebled(true)
    }

    //TODO:  Arrumar o redirecionamento ao excluir
    //FIXME: teoricamente foi kkk //

    function handleExcluir(e) {
        e.preventDefault()
        api.delete(`v1/usuarios/${props.id}`, {
            token: localStorage.getItem('sessionToken')
        })
            .then(res => {
                alert('Usuário excluido com sucesso')
                if (parseInt(props.id) === parseInt(props.idUsuario)) {
                    localStorage.clear()
                    navigate('/')
                } else {
                    if (props.tipo === SaltPassword("A")) {
                        navigate('/painel-de-controle/usuarios')
                    } else {
                        localStorage.clear()
                        navigate('/')
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className='p-5'>
            <Modal
                isOpen={props.modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={props.closeModal}
                onAfterClose={afterCloseModal}
                style={customStyles}
                contentLabel="Modal excluir usuário"
            >
                <div className="text-center">
                    <h2>Excluir usuário</h2>
                </div>
                <div className="mt-3 mb-2">Você tem certeza que deseja excluir essa conta permanentemente?</div>
                {/* FIXME:realizar verificação para excluir o usuário? */}
                <div className="row">
                    <button className="btn btn-danger mt-3 mb-3" onClick={(e) => handleExcluir(e)} disabled={isDisabled}>Excluir</button>
                    <button className="btn btn-success mb-1" onClick={props.closeModal}>Cancelar</button>
                </div>

            </Modal>
        </div>
    )
}
