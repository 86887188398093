/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/logo/eletrix5.png'

export default function inicio() {
    const navigate = useNavigate();

    function HandleInicio() {
        navigate("/entrar")
    }

    useEffect(() => {
        if (localStorage.getItem('sessionToken')) {
            navigate("/painel-de-controle/carregadores")
        } else {
            navigate("/entrar")
        }
    }, [])

    return (
        <div className="container-fluid vh-100">
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="col-12">
                    <div className="row">
                        <div className="d-flex justify-content-center mb-5">
                            <img src={Logo} className="img-entrar" alt="Logo eletrix" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-danger w-20" onClick={HandleInicio}>Inicio</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
