/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import Voltar from '../../assets/uteis/voltar.png'
import EditarUsuario from '../../components/Editar-usuario'
export default function editarUsuario(props) {
    const navigate = useNavigate()

    return (

        <div className="fluid">
            <div className="d-flex justify-content-between">

                <div className="col-3 mt-auto">
                    <Link to="#">
                        <Image src={Voltar} alt="voltar" width="auto" height="70px" onClick={() => navigate(-1)} />
                    </Link>
                </div>

                <div className="col-6 text-center mt-3">
                    <h1 className="justify-self=center">Editar usuário</h1>
                </div>

                <div className="col-3"></div>
            </div>

            <div className="row offset-md-3 col-md-6 col-10 offset-1 mt-5">
                <EditarUsuario />
            </div>

        </div>

    )
}
