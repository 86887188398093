import React, { useState } from 'react'
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '30px',
        paddingLeft: '80px',
        paddingRight: '80px',
        transform: 'translate(-50%, -50%)',
    },
};

export default function ModalSair(props) {
    const [isDisabled, setIsDisebled] = useState(true)

    function afterOpenModal() {
        setTimeout(() => { setIsDisebled(false) }, 500)
    }
    function afterCloseModal() {
        setIsDisebled(true)
    }

    function handleSair() {
        localStorage.clear();
        document.location = '/'
    }

    return (
        <div className='p-5'>
            <Modal
                isOpen={props.modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={props.closeModal}
                onAfterClose={afterCloseModal}
                style={customStyles}
                contentLabel="Modal excluir usuário"
            >
                <div className="text-center">
                    <h2>Sair da conta</h2>
                </div>
                <div className="mt-3 mb-2">Você tem certeza que deseja sair?</div>
                {/* FIXME:realizar verificação para excluir o usuário? */}
                <div className="row">
                    <button className="btn btn-danger mt-3 mb-3" onClick={(e) => handleSair(e)} disabled={isDisabled}>Sair</button>
                    <button className="btn btn-success mb-1" onClick={props.closeModal}>Cancelar</button>
                </div>

            </Modal>
        </div>
    )
}
