import React from "react";
import MenuLateral from "../../../components/Menu-lateral";

export default function PainelCarregadores() {
  return (
    //renderizar componentes diferentes de acordo com o selecionado no menu lateral
    <>
      <MenuLateral item1 />
    </>
  );
}
