import React from 'react';
import MenuLateral from '../../../components/Menu-lateral';

export default function painelCarregamentos() {
    return (
        <>
            <MenuLateral item4 />
        </>
    )
}

