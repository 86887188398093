/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import api from '../../../services/api'
import useSWR, { useSWRConfig } from 'swr';
import { useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import { useEffect } from 'react';
import './styles.css'

const fetcher = async (url) => {
    const res = await api.get(url)
    return (res.data)
}

export default function VisualizarCarregamentos() {
    let { id } = useParams();

    const { data } = useSWR(`/v1/carregamentos/${id}`, fetcher)

    const [dtCadastro, setDtCadastro] = useState('')
    const [idUsuario, setIdUsuario] = useState(0)
    const [idCarregador, setIdCarregador] = useState(0)
    const [nomeLocal, setNomeLocal] = useState('')
    const [energiaCarregada, setEnergiaCarregada] = useState(0)
    const [duracao, setDuracao] = useState('')
    const [valor, setValor] = useState(0)
    const [status, setStatus] = useState('')
    const [nota, setNota] = useState(0)
    const [comentario, setComentario] = useState('')

    const [endereco, setEndereco] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [cep, setCep] = useState(0)

    const [dtEncerramento, setDtEncerramento] = useState('')
    const [preco, setPreco] = useState(0)
    const [razao, setRazao] = useState('')
    const [cobrancaStatus, setCobrancaStatus] = useState('')
    const [cobrancaId, setCobrancaId] = useState(0)
    const [cobrancaFormaPagamento, setCobrancaFormaPagamento] = useState('')

    function maskJs(value, pattern) {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, () => v[i++] || '');
    };

    useEffect(() => {
        if (data) {
            setDtCadastro(data[0].dt_cadastro)
            setIdUsuario(data[0].id_usuario)
            setIdCarregador(data[0].id_carregador)
            setNomeLocal(data[0].nome_local)
            setEnergiaCarregada(data[0].energia_carregada)
            setDuracao(data[0].duracao)
            setValor(data[0].valor)
            setStatus(data[0].status)
            setNota(data[0].nota)
            setComentario(data[0].comentario)
            setEndereco(data[0].endereco);
            setComplemento(data[0].complemento)
            setBairro(data[0].bairro)
            setCidade(data[0].cidade)
            setUf(data[0].uf)
            setCep(maskJs(data[0].cep, '#####-###'))
            setDtEncerramento(data[0].dt_encerramento)
            setPreco(data[0].preco)
            setStatus(data[0].status)
            setRazao(data[0].razao)
            setCobrancaStatus(data[0].cobranca_status)
            setCobrancaId(data[0].cobranca_id)
            setCobrancaFormaPagamento(data[0].cobranca_forma_pagamento)
        }
        console.log(data)
    }, [data])


    return (
        <div className="container">
            {data?.length > 0 ?
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-md-4 mb-1">
                            {/* ID USUÁRIO */}
                            <label className="fw-bold">Id Usuário: </label>
                            <p>
                                <span className="text-center text-user">
                                    {idUsuario}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* ID CARREGADOR */}
                            <label className="fw-bold">Id Carregador: </label>
                            <p>
                                <span className="text-center text-user">
                                    {idCarregador}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* LOCAL */}
                            <label className="fw-bold">Local: </label>
                            <p>
                                <span className="text-center text-user">
                                    {nomeLocal}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* ENDEREÇO */}
                            <label className="fw-bold">Endereço: </label>
                            <p>
                                <span className="text-center text-user">
                                    {endereco}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* COMPLEMENTO */}
                            <label className="fw-bold">Complemento: </label>
                            <p>
                                <span className="text-center text-user">
                                    {complemento}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* BAIRRO */}
                            <label className="fw-bold">Bairro: </label>
                            <p>
                                <span className="text-center text-user">
                                    {bairro}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* CIDADE */}
                            <label className="fw-bold">Cidade: </label>
                            <p>
                                <span className="text-center text-user">
                                    {cidade}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* ESTADO */}
                            <label className="fw-bold">Estado: </label>
                            <p>
                                <span className="text-center text-user">
                                    {uf}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* CEP */}
                            <label className="fw-bold">CEP: </label>
                            <p>
                                <span className="text-center text-user">
                                    {cep}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* ENERGIA CARREGADA */}
                            <label className="fw-bold">Energia carregada: </label>
                            <p>
                                <span className="text-center text-user">
                                    {energiaCarregada}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* DURAÇÃO */}
                            <label className="fw-bold">Duração: </label>
                            <p>
                                <span className="text-center text-user">
                                    {duracao}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* VALOR */}
                            <label className="fw-bold">Valor: </label>
                            <p>
                                <span className="text-center text-user">
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* STATUS */}
                            <label className="fw-bold">Status: </label>
                            <p>
                                <span className="text-center text-user">
                                    {status == 'A' ? 'Ativo' : ''}
                                    {status == 'F' ? 'Fechado' : ''}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* NOTA */}
                            <label className="fw-bold">Nota: </label>
                            <p>
                                <span className="text-center text-user">
                                    {nota}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* COMENTÁRIO */}
                            <label className="fw-bold">Comentário: </label>
                            <p>
                                <span className="text-center text-user">
                                    {comentario}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4 mb-1">
                            {/* DATA CADASTRO */}
                            <label className="fw-bold">Data Cadastro: </label>
                            <p>
                                <span className="text-center text-user">
                                    {dtCadastro}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* DATA ENCERRAMENTO */}
                            <label className="fw-bold">Data Encerramento: </label>
                            <p>
                                <span className="text-center text-user">
                                    {dtEncerramento}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* PREÇO */}
                            <label className="fw-bold">Preço: </label>
                            <p>
                                <span className="text-center text-user">
                                    {new Intl.NumberFormat('pr-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(preco)}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* RAZÃO */}
                            <label className="fw-bold">Razão: </label>
                            <p>
                                <span className="text-center text-user">
                                    {razao}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* STATUS COBRANÇA */}
                            <label className="fw-bold">Status Cobrança: </label>
                            <p>
                                <span className="text-center text-user">
                                    {cobrancaStatus}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* COBRANÇA ID */}
                            <label className="fw-bold">Id Cobrança: </label>
                            <p>
                                <span className="text-center text-user">
                                    {cobrancaId}
                                </span>
                            </p>
                        </aside>
                        <aside className="col-md-4">
                            {/* FORMA DE PAGAMENTO */}
                            <label className="fw-bold">Forma de pagamento: </label>
                            <p>
                                <span className="text-center text-user">
                                    {cobrancaFormaPagamento}
                                </span>
                            </p>
                        </aside>
                    </div>
                </div>
                : ''}
        </div>
    )
}
