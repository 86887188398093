import React, { useEffect, useState } from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import InputLabel from "../../Inputs/InputLabel";
import "../index.css";

export default function InputSetItensPage(props) {
  const [itens, setItens] = useState(10);
  const [defaultValue, setDefaultValue] = useState(
    localStorage.getItem("itensPorPagina")
      ? Number(localStorage.getItem("itensPorPagina"))
      : Number(props.itensPorPagina)
  );

  /**Função para setar o número de itens por página */
  function handleInputChange(e) {
    setItens(Number(e));
  }

  useEffect(() => {
    props.SetItensPorPagina(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <InputLabel name="itens_pagina" text="Itens por página" />
      {/* INPUT ITENS_POR_PAGINA */}
      <div className="px-2">
        <NumberInput
          name="itens_pagina"
          min={10}
          maxW={50}
          defaultValue={defaultValue}
          onChange={(e) => handleInputChange(e)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </div>
      {/* BTN_ATUALIZAR */}
      <button
        className="btn btn-success"
        onClick={() => props.SetItensPorPagina(itens)}
      >
        Atualizar
      </button>
    </>
  );
}
