import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../../services/api";
import CryptoJS from "crypto-js";
import useSWR from "swr";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};

function PrivateRoute(props) {
  var bytes = CryptoJS.AES.decrypt(localStorage.getItem("uid"), "ID_ADM");
  var idNumber = bytes.toString(CryptoJS.enc.Utf8);
  const { data } = useSWR(`/v1/usuarios/${idNumber}`, fetcher, {
    refreshInterval: 10000,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (data[0].ativo !== "S" || data[0].tipo !== "A") {
        localStorage.clear();
        navigate("/entrar");
      }
      localStorage.setItem("sessionNome", data[0].nome);
    }
  }, [data]);

  // Verifica se o usuário está logado
  const logado = localStorage.getItem("sessionToken") ? true : false;

  // Se ele n estiver logado, voltar pra pág de Login
  return logado ? props.children : <Navigate to="/entrar" />;
}

export default PrivateRoute;
