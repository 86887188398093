import React, { useState } from "react";
import api from "../../../services/api";
import useSWR, { useSWRConfig } from "swr";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Inputs from "./inputs";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};

export default function EditarCarregador() {
  let { id } = useParams();
  const { data } = useSWR(`/v1/carregadores/${id}`, fetcher);
  const [msg, setMsg] = useState("");

  const [local, setLocal] = useState("");
  const [endereco, setEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");

  const [privado, setPrivado] = useState("");
  const [plug, setPlug] = useState("");
  const [ativo, setAtivo] = useState("");
  const [potencia, setPotencia] = useState("");
  const [horario, setHorario] = useState("");
  const [telefone, setTelefone] = useState("");
  const [status, setStatus] = useState("");
  const [preco, setPreco] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  const formatarTelefone = (numero) => {
    numero = numero.toString();
    console.log(numero.length);
    if (numero.length === 10) {
      return `(${numero.slice(0, 2)}) ${numero.slice(2, 6)}-${numero.slice(6)}`;
    } else if (numero.length === 11) {
      return `(${numero.slice(0, 2)}) ${numero.slice(2, 7)}-${numero.slice(7)}`;
    } else {
      return "Número de telefone inválido";
    }
  };

  function handleSalvar(e) {
    e.preventDefault();
    let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
    console.log(formatarTelefone(tel));

    let newCep = cep.replace("-", "");
    api
      .patch(`/v1/carregadores/${id}`, {
        nome_local: local,
        privado: privado,
        id_plug: 2,
        plug: plug,
        ativo: ativo,
        endereco: endereco,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        uf: uf,
        cep: newCep,
        cod_cidade: null,
        potencia: potencia,
        horario: horario,
        telefone: formatarTelefone(tel),
        distancia: null,
        status: status,
        latitude: latitude,
        longitude: longitude,
        preco: preco,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setMsg("Dados alterados com sucesso");
          setTimeout(() => setMsg(""), 2000);
        } else {
          alert("ocorreu um erro ao enviar a requisição");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleExcluir(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function maskJs(value, pattern) {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, () => v[i++] || "");
  }

  useEffect(() => {
    if (data) {
      setLocal(data[0].nome_local);
      setEndereco(data[0].endereco);
      setComplemento(data[0].complemento);
      setBairro(data[0].bairro);
      setCidade(data[0].cidade);
      setUf(data[0].uf);
      setCep(maskJs(data[0].cep, "#####-###"));
      setPrivado(data[0].privado);
      setPlug(data[0].plug);
      setAtivo(data[0].ativo);
      setPotencia(data[0].potencia);
      setHorario(data[0].horario);
      setTelefone(data[0].telefone);
      setStatus(data[0].status);
      setPreco(data[0].preco);
      setLatitude(data[0].latitude);
      setLongitude(data[0].longitude);
    }
  }, [data]);

  return (
    <div className="container">
      {data?.length > 0 ? (
        <Inputs
          id={id}
          local={local}
          endereco={endereco}
          complemento={complemento}
          bairro={bairro}
          cidade={cidade}
          uf={uf}
          cep={cep}
          privado={privado}
          plug={plug}
          ativo={ativo}
          potencia={potencia}
          horario={horario}
          telefone={telefone}
          status={status}
          msg={msg}
          latitude={latitude}
          longitude={longitude}
          handleExcluir={handleExcluir}
          handleSalvar={handleSalvar}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          setMsg={setMsg}
          setLocal={setLocal}
          setEndereco={setEndereco}
          setComplemento={setComplemento}
          setBairro={setBairro}
          setCidade={setCidade}
          setUf={setUf}
          setCep={setCep}
          setPrivado={setPrivado}
          setPlug={setPlug}
          setAtivo={setAtivo}
          setPotencia={setPotencia}
          setHorario={setHorario}
          setTelefone={setTelefone}
          setStatus={setStatus}
          preco={preco}
          setPreco={setPreco}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
        />
      ) : null}
    </div>
  );
}
