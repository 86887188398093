/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import api from "./../services/api";

export default {
  getUsersPagamento: async () => {
    let res = [];
    await api
      .get(`/v1/usuarios/`)
      .then((response) => {
        if (response.data?.length > 0) {
          response.data.map((itens) => {
            res.push({ id_usuario: itens.id_usuario, nome: itens.nome });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return res || [];
  },
  getCarregamentosPagamento: async () => {
    let res = [];
    await api
      .get(`/v1/carregamentos/`)
      .then((response) => {
        if (response.data?.length > 0) {
          response.data.map((itens) => {
            res.push({
              id_carregamento: itens.id_carregamento,
              id_pagamento: itens.id_pagamento,
              energia_carregada: itens.energia_carregada,
              unidade_energia: itens.unidade_energia,
              id_carregador: itens.id_carregador,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return res || [];
  },
};
