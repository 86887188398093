import { Image } from '@chakra-ui/react'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AddCarregador from '../../components/Carregadores/adicionarCarregador'
import Voltar from '../../assets/uteis/voltar.png'

export default function AdicionarCarregador() {
  const navigate = useNavigate();
  return (
    <div className='overflow-auto overflow-md-hidden vh-100'>
      <div className="d-flex justify-content-between m-2">
        <div className="col-3">
          <Link to="#">
            <Image src={Voltar} alt="" height="10vh" onClick={() => navigate(-1)} />
          </Link>
        </div>
        <div className="col-6 text-center mb-5">
          <h1>Adicionar Carregador</h1>
        </div>
        <div className="col-3"></div>
      </div>
      <AddCarregador />
    </div>

  )
}
