import React from "react";
import Editar from "../../assets/uteis/create-outline.svg";

export default function ButtonEdit({ editar, handleEdit }) {
  return (
    <button
      className="btn text-center w-100 h-100 border-0"
      onClick={(e) => {
        handleEdit(editar);
      }}
      title="Editar"
    >
      <img src={Editar} alt="Editar" width={19}></img>
    </button>
  );
}
