import React from "react";
import ReactDOM from "react-dom/client";
import Rotas from "./rotas";
import "./global.css";
import { UserProvider } from "./contexts/user";

//TODO: tirar esses comentários ao por em produção
//FIXME: index.js // API

if (document.location.protocol !== "https:") {
  const httpsURL = "https://" + document.location.href.split("//")[1];
  document.location.replace(httpsURL);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <Rotas />
    </UserProvider>
  </React.StrictMode>
);
