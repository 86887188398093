/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { IMaskInput } from "react-imask";
import Logo from '../../assets/logo/eletrix5.png'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../services/api'
import InputSenha from '../../components/Input-senha';
import { Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import SaltPassword from './../../services/md5';
import FormCodigo from '../../components/Digitar-codigo/form-codigo'
export default function cadastro() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [msg, setMsg] = useState('')
  // const [loading, setLoading] = useState(false)

  //VERIFICA SE O EMAIL É VÁLIDO
  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidNome() {
    return /^([a-zA-Zà-úÀ-Ú]|\s+)+$/.test(nome);
  }

  function CriarConta(e, senha, confirmarSenha) {
    e.preventDefault();
    //deixar telefone sem caracteres especiais
    let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')

    if (senha === confirmarSenha && senha.length >= 4 &&
      tel.length === 11 && isValidEmail(email) && nome.length > 0 && isValidNome(nome)) {
      api.post("/v1/usuarios/validaremail", {
        email
      }).then(res => {
        //email inativo/inexistente
        if (res.data.status === "ok") {
          localStorage.setItem('sessionEmail', email)
          localStorage.setItem('sessionTipoCodigo', 'C')
          navigate(`/cadastro/codigo/?nome=${nome}&pas=${senha}&tel=${tel}`)
        } else {
          setMsg('Ocorreu um erro inesperado, tente novamente mais tarde')
          setTimeout(() => setMsg(''), 3000)
        }

      }).catch(error => {
        //devolve msg informando q o email ainda esta ativo/cadastrado
        console.log(error)
        setMsg('E-mail já está em uso, faça login ou redefina sua senha')
        setTimeout(() => setMsg(''), 3000)
      })
    } else {
      if (senha.length < 4) {
        setMsg('A senha deve ter no mínimo 4 caracteres')
        setTimeout(() => setMsg(''), 3000)
      } else if (tel.length < 11) {
        setMsg('Telefone inválido')
        setTimeout(() => setMsg(''), 3000)
      } else if (!isValidEmail(email)) {
        setMsg('Email inválido')
        setTimeout(() => setMsg(''), 3000)
      } else if (nome.length <= 0) {
        setMsg('Digite seu nome')
        setTimeout(() => setMsg(''), 3000)
      } else if (!isValidNome(nome)) {
        setMsg('Retire números e caracteres especiais do nome')
        setTimeout(() => setMsg(''), 3000)
      }
      else {
        setMsg('As senhas não correspondem')
        setTimeout(() => setMsg(''), 3000)
      }
    }
  }

  useEffect(() => {
    if (email.length > 0) {
      if (!isValidEmail(email)) {
        document.getElementById("inputEmail").classList.add('is-invalid');
      } else {
        document.getElementById("inputEmail").classList.remove('is-invalid');
        document.getElementById("inputEmail").classList.add('is-valid');
      }
    } else {
      document.getElementById("inputEmail").classList.remove('is-invalid');
      document.getElementById("inputEmail").classList.remove('is-valid');
    }
  }, [email, isValidEmail])

  useEffect(() => {
    if (nome.length > 0) {
      if (!isValidNome(nome)) {
        document.getElementById("inputNome").classList.add('is-invalid');
      } else {
        document.getElementById("inputNome").classList.remove('is-invalid');
        document.getElementById("inputNome").classList.add('is-valid');
      }
    } else {
      document.getElementById("inputNome").classList.remove('is-invalid');
      document.getElementById("inputNome").classList.remove('is-valid');
    }
  }, [isValidNome, nome])

  useEffect(() => {
    if (telefone.length < 15) {
      document.getElementById("inputTelefone").classList.add('is-invalid');
    } else {
      document.getElementById("inputTelefone").classList.remove('is-invalid');
      document.getElementById("inputTelefone").classList.add('is-valid');
    }

  }, [telefone])

  useEffect(() => {
    document.getElementById("inputEmail").classList.remove('is-invalid');
    document.getElementById("inputTelefone").classList.remove('is-invalid');
    document.getElementById("inputNome").classList.remove('is-invalid');
  }, [])

  // useEffect(() => {
  //   if (email.length > 0 &&
  //     nome.length > 0 &&
  //     telefone.length > 0
  //   ) { setLoading(false) }
  //   else { setLoading(true) }
  // }, [email, nome, telefone, sucesso, msg])

  // IMASK APAGA AO DIGITAR EM OUTROS INPUTS
  // FIXME: TEORICAMENTE EU JA RESOLVI NOS INPUTS (faz mto tempo q n vi esse código kkkk)
  return (
    <div className="container-fluid vh-100">
      <div className="d-flex justify-content-center align-items-center vh-md-100 vh-100">
        <div className="row mt-sm-5 vw-30 vw-sm-80" id="form-criar-conta">
          <div className="d-flex justify-content-center mb-3">
            <Image src={Logo} className="img-entrar align-self-center" height="100px" alt="Logo eletrix" />
          </div>

          <div className="col-md-12 offset-md-0 col-12  ">
            <form>
              {/* nome do usuario */}
              <div className="mb-3">
                <label htmlFor="InputNome">Nome completo: </label>
                <input
                  type="text"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                  className="form-control"
                  placeholder="Digite seu nome completo"
                  id="inputNome"
                  aria-describedby="Nome do usuario"
                />
              </div>
              {/* email do usuario */}
              <div className="mb-3">
                <label htmlFor="InputEmail">E-mail: </label>
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="form-control"
                  placeholder="Digite seu Email"
                  id="inputEmail"
                  aria-describedby="Email"
                />
              </div>
              {/* telefone do usuario */}
              <div className="mb-3">
                <label htmlFor="InputTelefone">Telefone: </label>
                <IMaskInput
                  autoComplete="off"
                  mask="(00) 00000-0000"
                  placeholder="(XX) XXXXX-XXXX"
                  onKeyUp={(e) => setTelefone(e.target.value)}
                  value={telefone}
                  className="form-control"
                  id="inputTelefone"
                  aria-describedby="Telefone"
                />
              </div>
              {/* senhas do usuario */}
              <InputSenha
                HandleSenha={CriarConta}
                sucesso={sucesso}
                msg={msg}
              />
            </form>
            <div className="row d-flex mt-4 text-center">
              <Link to="/entrar" className="link-conta fw-bolder mb-3">Já possuo uma conta</Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
