/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import Inputs from '../editar-carregadores/inputs'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom';

export default function addCarregador() {
    const [msg, setMsg] = useState('')
    const navigate = useNavigate();

    const [local, setLocal] = useState('')
    const [endereco, setEndereco] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [cep, setCep] = useState('')

    const [privado, setPrivado] = useState('')
    const [plug, setPlug] = useState('')
    const [ativo, setAtivo] = useState('')
    const [potencia, setPotencia] = useState('')
    const [horario, setHorario] = useState('')
    const [telefone, setTelefone] = useState('')
    const [status, setStatus] = useState('')
    const [preco, setPreco] = useState(0)
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)


    //TODO: Arrumar input select (seleção precisa estar no primeiro valor)


    function handleSalvar(e) {
        e.preventDefault();
        let data = new Date().toISOString().replace('T', ' ').replace('Z', '');
        let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
        let newCep = cep.replace('-', '')
        //TODO: ver o id_plug
        api.post('/v1/carregadores/', {
            nome_local: local,
            privado,
            id_plug: 2,
            plug,
            dt_cadastro: data,
            ativo,
            endereco,
            complemento,
            bairro,
            cidade,
            uf,
            cep: newCep,
            cod_cidade: null,
            potencia,
            horario,
            telefone: tel,
            distancia: null,
            status,
            latitude: latitude,
            longitude: longitude,
            preco: preco
        }
        )
            .then(res => {
                alert('Carregador criado com sucesso')
                navigate('/painel-de-controle/carregadores')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="container">
            <Inputs
                msg={msg}
                local={local}
                endereco={endereco}
                complemento={complemento}
                bairro={bairro}
                cidade={cidade}
                uf={uf}
                cep={cep}
                privado={privado}
                plug={plug}
                ativo={ativo}
                potencia={potencia}
                horario={horario}
                telefone={telefone}
                status={status}
                latitude={latitude}
                longitude={longitude}

                handleSalvar={handleSalvar}

                setLocal={setLocal}
                setEndereco={setEndereco}
                setComplemento={setComplemento}
                setBairro={setBairro}
                setCidade={setCidade}
                setUf={setUf}
                setCep={setCep}
                setPrivado={setPrivado}
                setPlug={setPlug}
                setAtivo={setAtivo}
                setPotencia={setPotencia}
                setHorario={setHorario}
                setTelefone={setTelefone}
                setStatus={setStatus}
                preco={preco}
                setPreco={setPreco}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
            />
        </div>
    )
}
