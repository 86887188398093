import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRoute from './components/Private-route/private-route'
import Entrar from './pages/entrar'
import Cadastro from './pages/cadastro'
import Inicio from './pages/inicio'
import RecuperarSenha from './pages/recuperar-senha'
import DigitarCodigo from './pages/digitar-codigo'
import AlterarSenha from './pages/alterar-senha/alterar-senha'
import CadastroCodigo from './pages/cadastro/codigo'
//painel de controle
import PainelMapa from './pages/painel-de-controle/mapa';
import PainelCarregadores from './pages/painel-de-controle/carregadores';
import PainelCarregamentos from './pages/painel-de-controle/carregamentos'
import PainelUsuarios from './pages/painel-de-controle/usuarios'
import PainelPagamentos from './pages/painel-de-controle/pagamentos'
import EditarUsuario from './pages/perfil'
import EditarCarregador from './pages/carregadores'
import AddCarregador from './pages/adicionar-carregador'
import EditarCarregamento from './pages/carregamento'
// import RedirectRoute from './pages/Redirect/redirect.jsx';

export default function Rotas() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Inicio />} errorElement={<Inicio />} />
                    <Route exact path="/entrar" element={<Entrar />} />
                    <Route exact path="/cadastro" element={<Cadastro />} />
                    <Route path="perfil">
                        <Route path=":id" element={<PrivateRoute><EditarUsuario /></PrivateRoute>} />
                    </Route>
                    <Route path="carregadores">
                        <Route path=":id" element={<PrivateRoute><EditarCarregador /></PrivateRoute>} />
                    </Route>
                    <Route path="carregamentos">
                        <Route path=":id" element={<PrivateRoute><EditarCarregamento /></PrivateRoute>} />
                    </Route>
                    {/* <Route exact path="/perfil/:id" element={<EditarUsuario />} /> */}
                    <Route exact path="/cadastro/codigo" element={<CadastroCodigo />} />
                    <Route exact path="/recuperar-senha" element={<RecuperarSenha />} />
                    <Route exact path="/digitar-codigo" element={<DigitarCodigo />} />
                    <Route exact path="/alterar-senha" element={<AlterarSenha />} />
                    <Route path="/adicionar-carregador" element={<PrivateRoute><AddCarregador /></PrivateRoute>} />
                    <Route path="painel-de-controle">
                        <Route path="mapa" element={<PrivateRoute><PainelMapa /></PrivateRoute>} />
                        <Route path="mapa/:id_view" element={<PrivateRoute><PainelMapa /></PrivateRoute>} />
                        <Route path="carregadores" element={<PrivateRoute><PainelCarregadores /></PrivateRoute>} />
                        <Route path="carregamentos" element={<PrivateRoute><PainelCarregamentos /></PrivateRoute>} />
                        <Route path="usuarios" element={<PrivateRoute><PainelUsuarios /></PrivateRoute>} />
                        <Route path="pagamentos" element={<PrivateRoute><PainelPagamentos /></PrivateRoute>} />
                    </Route>
                    {/* <Route path="/redirect-app" element={<RedirectRoute/>} /> */}
                </Routes>
            </BrowserRouter>
        </>
    )
}