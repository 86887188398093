import axios from "axios";

const api = axios.create({
  baseURL: "https://apl001.eletrix5.com",
  //baseURL: "http://192.168.15.100:889",
});

api.interceptors.request.use(
  (req) => {
    if (localStorage.getItem("sessionToken")) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        "sessionToken"
      )}`;
    }
    return req;
  },
  (error) => {
    console.log(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
    }
    return error;
  }
);

export default api;
