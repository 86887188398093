/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./styles.css";

export default function pesquisa({
  setPesquisa,
  PesquisaAtual,
  tamanho,
  className,
  handlePesquisar,
  handleApagar,
}) {
  function Teclas(e) {
    switch (e.code) {
      case "NumpadEnter":
      case "Enter":
        handlePesquisar();
        break;
      default:
        return null;
    }
  }

  return (
    <div className={tamanho < 400 ? "d-block" : "d-flex"}>
      {/* deixar responsivo no celular */}
      <input
        type="text"
        className={`${className ? className : null} form-control w-auto`}
        value={PesquisaAtual}
        onChange={(e) => setPesquisa(e.target.value)}
        placeholder="Pesquisar"
        onKeyUp={(e) => Teclas(e)}
      />
      <button
        className="teste btn fw-bold"
        onClick={(e) => handleApagar(e)}
        title="Apagar pesquisa"
      >
        X
      </button>
      <button
        className={`${className ? className : null} btn btn-success`}
        onClick={(e) => handlePesquisar(e)}
      >
        Pesquisar
      </button>
    </div>
  );
}
