import { Button, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Fechar from '../../assets/uteis/fechar.png'
import Logo from '../../assets/logo/eletrix5.png'
import api from '../../services/api'
import SaltPassword from '../../services/md5'
import InputSenha from '../../components/Input-senha'
export default function AlterarSenha() {
    const navigate = useNavigate()


    const [msg, setMsg] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const verificaEmail = localStorage.getItem('sessionEmail') ? true : false;
        const verificaCodigo = localStorage.getItem('sessionCode') ? true : false;
        //caso o usuario tente entrar na pagina pela url, será redirecionado altomaticamente
        if (verificaEmail !== true || verificaCodigo !== true) {
            navigate('/')
        }
    }, [])

    function MudarSenha(e, senha, confirmarSenha) {
        e.preventDefault()
        setMsg('')
        setLoading(true)

        if (senha === confirmarSenha && senha.length >= 4) {
            api.patch("/v1/usuarios/esqueceusenha", {
                email: localStorage.getItem('sessionEmail'),
                codigo_senha: localStorage.getItem('sessionCode'),
                senha: SaltPassword(senha)
            })
                .then(res => {
                    //mostrar q deu certo e redirecionar p/ inicio
                    setSucesso('Senha alterada com sucesso.')
                    localStorage.removeItem('sessionTipoCodigo')
                    localStorage.removeItem('sessionCode')
                    setTimeout(() => setSucesso(''), 700)
                    setTimeout(() => navigate('/'), 1000)

                }).catch(error => {
                    setMsg(error)
                })
        } else {
            if (senha.length < 4) {
                setMsg('Sua senha deve ter no minimo 4 caracteres')
            } else {
                setMsg('Ocorreu um erro ao trocar de senha, verifique se elas correspondem ou tente novamente mais tarde')
            }
            setSucesso('')
            setTimeout(() => setMsg(''), 3000)
        }
        setLoading(false)
    }

    return (
        <div className="container-fluid">
            <div className='vh-10'>
                <Link to="/entrar">
                    <Image src={Fechar} alt="voltar" width="auto" height="70px" onClick={(e) => localStorage.clear()} />
                </Link>
            </div>
            <div className="d-flex justify-content-center align-items-center vh-80">
                <div className="row">
                    <div className="row d-flex justify-content-center mb-5">
                        <img src={Logo} className="img-entrar align-self-center" alt="Logo eletrix" />
                    </div>
                    <div className="col-lg-8 offset-lg-2 col-8 offset-2">
                        <form>
                            <InputSenha
                                HandleSenha={MudarSenha}
                                loading={loading}
                                sucesso={sucesso}
                                msg={msg}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
