/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalSair from "./modal-sair";
import "../styles.css";

function itens(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [teste, setTeste] = useState(localStorage.getItem("menuOpen"));
  const [nome, setNome] = useState(localStorage.getItem("sessionNome"));

  function handleSair(e) {
    e.preventDefault();
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    setTeste(props.active.toString());
    setNome(localStorage.getItem("sessionNome"));
  }, [props.active]);

  return (
    <>
      {/* Cada <li> é um item do menu lateral */}
      {/* Ao add novo item, add item na pag "Menu" (ITENS) */}
      {/* Nas "pages" add em "painel-de-controle" componente com prop com a numeração do item*/}

      {/* Mapa */}
      {/*TODO: MUDAR NUMERO DOS ITENS*/}
      {/* <li className={`${props.item3 ? 'hovered' : ''} ${props.active === false ? 'active' : ''}`}>
                <Link to="/painel-de-controle/mapa">
                    <span className="icone">
                        <ion-icon name="map-sharp"></ion-icon>
                    </span>
                    <span className="titulo">Mapa</span>
                </Link>
            </li> */}

      {/* CARREGADORES */}
      <li
        className={`${props.item1 ? "hovered" : ""} ${
          props.active === false ? "active" : ""
        }`}
      >
        <Link to="/painel-de-controle/carregadores">
          <span className="icone">
            <ion-icon name="battery-charging-sharp"></ion-icon>
          </span>
          <span className="titulo">Carregadores</span>
        </Link>
      </li>

      {/* CARREGAMENTOS */}
      <li
        className={`${props.item4 ? "hovered" : ""} ${
          props.active === false ? "active" : ""
        }`}
      >
        <Link to="/painel-de-controle/carregamentos">
          {/*<span className="icone"><ion-icon name="reader-outline"></ion-icon></span>*/}
          <span className="icone">
            <ion-icon name="hourglass-outline"></ion-icon>
          </span>

          <span className="titulo">Carregamentos</span>
        </Link>
      </li>

      {/* USUÁRIOS */}
      <li
        className={`${props.item2 ? "hovered" : ""} ${
          props.active === false ? "active" : ""
        }`}
      >
        <Link to="/painel-de-controle/usuarios">
          <span className="icone">
            <ion-icon name="person"></ion-icon>
          </span>
          <span className="titulo">Usuários</span>
        </Link>
      </li>

      {/* PAGAMENTOS */}
      <li
        className={`${props.item5 ? "hovered" : ""} ${
          props.active === false ? "active" : ""
        }`}
      >
        <Link to="/painel-de-controle/pagamentos">
          <span className="icone">
            <ion-icon name="card-outline"></ion-icon>
          </span>
          <span className="titulo">Pagamentos</span>
        </Link>
      </li>

      {/* SAIR */}
      <li className={`${props.active === false ? "active" : ""}`}>
        <Link onClick={handleSair}>
          <span className="icone">
            <ion-icon name="exit-outline"></ion-icon>
          </span>
          <span className="titulo">Sair</span>
        </Link>
      </li>

      <ModalSair
        modalOpen={handleSair}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
      />
    </>
  );
}

export default itens;
