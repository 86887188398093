import React, { useState } from 'react';
import Logo from '../../assets/logo/eletrix5.png'
import { Image } from '@chakra-ui/react'
import Voltar from '../../assets/uteis/voltar.png'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../services/api'
import './styles.css';

export default function RecuperarSenha() {

    const navigate = useNavigate();
    const [email, setEmail] = useState(localStorage.getItem('sessionEmail') ? localStorage.getItem('sessionEmail') : '');
    const [sucesso, setSucesso] = useState('');
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);

    //verificar se o email do usuario está cadastrado
    function HandleRecuperarSenha(e) {
        e.preventDefault()
        api.post("/v1/usuarios/esqueceusenha", {
            email
        })
            .then(res => {
                setSucesso('')
                if (res.request.status === 500) {
                    setMsg('Email não localizado')
                    setTimeout(() => setMsg(''), 3000)
                } else {
                    localStorage.setItem('sessionEmail', email)
                    localStorage.setItem('sessionTipoCodigo', 'R')
                    //C = criar conta R=recuperar senha
                    navigate('/digitar-codigo')
                }
            })
            .catch(error => {
                setMsg('Email não encontado')
                console.log('Erro')
            })
    }

    function Teclas(e) {
        e.preventDefault()
        switch (e.code) {
            case "NumpadEnter": case "Enter":
                HandleRecuperarSenha(e)
                break;
            default:
                return null;
        }
    }

    return (
        <div className="container-fluid">
            <div className='vh-10'>
                <Link to="/entrar">
                    <Image src={Voltar} alt="voltar" width="auto" height="70px" className="ms-2" />
                </Link>
            </div>
            <div className="d-flex justify-content-center align-items-center vh-80">
                <div className="row">
                    <div className="d-flex justify-content-center mb-1">
                        <img src={Logo} className="img-entrar align-self-center" alt="Logo eletrix" />
                    </div>
                    <div className="col-lg-8 offset-lg-2 col-8 offset-2 mt-5">
                        <form>
                            <div className="mb-3">
                                {/* Email do usuário */}
                                <label htmlFor="InputEmail">E-mail: </label>
                                <input
                                    type="text"
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyUp={(e) => Teclas(e)}
                                    value={email}
                                    className="form-control"
                                    placeholder="Digite seu Email"
                                    id="inputEmail"
                                    aria-describedby="Email"
                                />
                            </div>

                            <div className="d-flex justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-principal"
                                    onClick={(e) => HandleRecuperarSenha(e)}
                                    disabled={loading}
                                >
                                    {loading ?
                                        <div>
                                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                            <span className="ms-2">Enviando...</span>
                                        </div> : <span className="">Acessar</span>}
                                </button>
                            </div>
                        </form>

                        {sucesso === 'N' ? <div className="alert alert-danger mt-4" role="alert"> Ocorreu um erro ao enviar email, tente novamente</div> : null}
                        {msg.length > 0 ? <div className="alert alert-danger mt-4" role="alert"> {msg} </div> : null}

                    </div>
                </div>
            </div>
        </div>
    )
}
