/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";
import api from "../../services/api";
import ModalExcluir from "./modal";
import { RadioGroup } from "@chakra-ui/react";
import { UserContext } from "../../contexts/user";
import SaltPassword from "../../services/md5";

export default function EditarUsuario() {
  let { id } = useParams();
  const { tipo, idUsuario } = useContext(UserContext);
  const [data, setData] = useState([]);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipoSelect, setTipoSelect] = useState("");
  const [msg, setMsg] = useState("");

  const [valido, setValido] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidNome() {
    return /^([a-zA-Zà-úÀ-Ú]|\s+)+$/.test(nome);
  }

  function handleSalvar(e) {
    e.preventDefault();
    let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
    api
      .patch(`/v1/usuarios/${id}`, {
        id_usuario: id,
        nome: nome.trim(),
        email: email,
        telefone: tel,
        tipo: tipoSelect,
      })
      .then((res) => {
        if (localStorage.getItem("sessionId") === SaltPassword(`${id}`)) {
          localStorage.setItem("sessionTipo", SaltPassword(`${tipoSelect}`));
        }
        setMsg("Dados alterados com sucesso");
        setTimeout(() => setMsg(""), 3000);
      })
      .catch((error) => {
        console.log("ocorreu um erro inesperado");
        console.log(error);
      });
  }

  function handleExcluir(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function handleEditTipo(e) {
    setTipoSelect(e.target.value);
  }

  useEffect(() => {
    api
      .get(`/v1/usuarios/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setNome(data[0].nome);
      setEmail(data[0].email);
      setTelefone(data[0].telefone);
      setTipoSelect(data[0].tipo);
    }
  }, [data]);

  useEffect(() => {
    if (email.length > 0) {
      if (!isValidEmail(email)) {
        if (document.getElementById("inputEmail")) {
          document.getElementById("inputEmail").classList.add("is-invalid");
        }
      } else {
        if (document.getElementById("inputEmail")) {
          document.getElementById("inputEmail").classList.remove("is-invalid");
          document.getElementById("inputEmail").classList.add("is-valid");
        }
      }
    }

    if (nome.length > 0) {
      if (!isValidNome(nome)) {
        if (document.getElementById("inputNome")) {
          document.getElementById("inputNome").classList.add("is-invalid");
        }
      } else {
        if (document.getElementById("inputNome")) {
          document.getElementById("inputNome").classList.remove("is-invalid");
          document.getElementById("inputNome").classList.add("is-valid");
        }
      }
    }

    if (telefone.length > 0) {
      let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
      if (tel.length !== 11 && tel.length !== 10) {
        if (document.getElementById("inputTelefone")) {
          document.getElementById("inputTelefone").classList.add("is-invalid");
        }
      } else {
        if (document.getElementById("inputTelefone")) {
          document
            .getElementById("inputTelefone")
            .classList.remove("is-invalid");
          document.getElementById("inputTelefone").classList.add("is-valid");
        }
      }
    }
  }, [email, isValidEmail, nome, isValidNome, telefone]);

  useEffect(() => {
    if (telefone.length > 0 && email.length > 0 && nome.length > 0) {
      let tel = telefone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
      if (
        (tel.length === 11 || tel.length === 10) &&
        isValidEmail(email) &&
        isValidNome(nome)
      ) {
        setValido(true);
      } else {
        setValido(false);
      }
    }
  }, [telefone, nome, email]);
  //FIXME: SaltPassword usado para os dados sem criptografia ficarem criptografados
  //iguais no localstorage(se o usuario mudar manualmente, não irá funcionar)
  return (
    <div>
      {(localStorage.getItem("sessionTipo") === SaltPassword("A") ||
        SaltPassword(`${id}`) === localStorage.getItem("sessionId")) &&
      data?.length > 0 ? (
        <form>
          <div className="mb-4">
            {/* Nome do usuário */}
            <label htmlFor="InputNome" className="fw-bold">
              Nome:{" "}
            </label>
            <input
              type="text"
              id="inputNome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              className="form-control "
            />
          </div>
          <div className="mb-4">
            {/* Email do usuário */}
            <label htmlFor="InputEmail" className="fw-bold">
              Email:{" "}
            </label>

            <input
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control "
            />
          </div>
          <div className="mb-4">
            {/* Telefone do usuário */}
            <label htmlFor="InputTelefone" className="fw-bold">
              Telefone:{" "}
            </label>
            <IMaskInput
              autoComplete="off"
              mask="(00) 00000-0000"
              placeholder="(XX) XXXXX-XXXX"
              value={telefone}
              className="form-control "
              id="inputTelefone"
              onKeyUp={(e) => setTelefone(e.target.value)}
              onChange={(e) => setTelefone(e.target.value)}
              aria-describedby="Telefone"
            />
          </div>
          {localStorage.getItem("sessionTipo") === SaltPassword("A") ||
          tipoSelect === "A" ? (
            /* Tipo do usuário */
            <div className="mb-4">
              <RadioGroup>
                <input
                  readOnly
                  className="form-check-input"
                  checked={tipoSelect === "A"}
                  value="A"
                  type="radio"
                  name="exampleRadios"
                  onClick={(e) => handleEditTipo(e)}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="exampleRadios2"
                >
                  Admin
                </label>
                <input
                  readOnly
                  className="form-check-input ms-4"
                  checked={tipoSelect === "C"}
                  value="C"
                  type="radio"
                  name="exampleRadios"
                  onClick={(e) => handleEditTipo(e)}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="exampleRadios2"
                >
                  Comum
                </label>
              </RadioGroup>
            </div>
          ) : null}
          <div className="mt-5 ">
            {msg.length > 0 ? (
              <span className="alert alert-success">{msg}</span>
            ) : null}
          </div>
          <div className="mb-4 mt-4">
            <button
              className="btn btn-success  w-100"
              onClick={(e) => {
                handleSalvar(e);
              }}
              disabled={!valido}
            >
              <span>Salvar</span>
            </button>
          </div>
          <div>
            <button
              className="btn btn-danger text-center w-100"
              onClick={(e) => {
                handleExcluir(e);
              }}
            >
              <span>Excluir</span>
            </button>
          </div>
        </form>
      ) : null}

      {localStorage.getItem("sessionTipo") === SaltPassword("C") &&
      localStorage.getItem("sessionId") !== SaltPassword(id) ? (
        <div className="d-flex align-content-center justify-content-center">
          Acessando outro usuário
        </div>
      ) : null}

      <ModalExcluir
        modalOpen={handleExcluir}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        idUsuario={idUsuario}
        id={id}
        tipo={tipo}
      />
    </div>
  );
}
