/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/rules-of-hooks */
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React, { useState, useEffect } from 'react'
import api from '../../../services/api'
import useSWR from 'swr'
import { useParams } from 'react-router-dom';
import pinDisponivel from "../../../assets/icones/carregadores_pin/pin_disponivel.webp"
import pinIndisponivel from "../../../assets/icones/carregadores_pin/pin_indisponivel.webp"
import pinEmUso from "../../../assets/icones/carregadores_pin/pin_em_uso.webp"
import Sidebar from '../../Sidebar/'
const mapContainerStyle = {
    height: "80vh",
    width: "100%"
}

const fetcher = async (url) => {
    const res = await api.get(url)
    return (res.data)
}

export default function listarMapa() {
    const { data } = useSWR(`/v1/carregadores`, fetcher, { refreshInterval: 2500 })
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: `${process.env.REACT_APP_MAPS_KEY}`
    })

    const [zoom, setZoom] = useState(18);
    const [center, setCenter] = useState([{
        lat: -23.424077,
        lng: -46.310100
    }]);
    const [open, setOpen] = useState(false)
    let { id_view } = useParams();

    const [id, setId] = useState(0);

    function openSidebar(visible, id) {
        setId(id)
        setOpen(visible);
    }

    useEffect(() => {
        if (id_view) {
            let centro = data?.filter(itens => itens.id_carregador == id_view);
            if (centro?.length > 0) {
                setCenter([{
                    lat: centro[0].latitude,
                    lng: centro[0].longitude
                }])
            }
        } else {
            //pegar localização atual do usuario que estiver acessando
            // navigator.geolocation.getCurrentPosition(function (position) {
            //     setCenter([{ lat: position.coords.latitude, lng: position.coords.longitude }])
            // });
        }
    }, [data])

    return (
        <>
            <Sidebar open={open} openSidebar={openSidebar} id={id} />
            {isLoaded ?
                <>
                    <GoogleMap
                        id="marker-example"
                        mapContainerStyle={mapContainerStyle}
                        zoom={zoom}
                        center={center[0]}

                    >
                        {/* 
                        Inicia o StreetView
                        <StreetViewPanorama
                            position={center[0]}
                            visible={true}
                        /> 
                        */}
                        {data?.map(carregador => {

                            return (

                                <div key={carregador.id_carregador}>

                                    <Marker
                                        //se status for igual a D = pinDisponivel
                                        //se status for igual a U = pinEmUso
                                        //se status for <> D && U = pinIndisponivel
                                        icon={
                                            carregador.status === "D"
                                                ? pinDisponivel
                                                : carregador.status === "U"
                                                    ? pinEmUso
                                                    : pinIndisponivel
                                        }
                                        position={
                                            {
                                                lat: carregador.latitude,
                                                lng: carregador.longitude
                                            }
                                        }
                                        options={{
                                            label: {
                                                text: `${carregador.status === "D"
                                                    ? "disponivel"
                                                    : carregador.status === "U"
                                                        ? "Carregando"
                                                        : "Indisponível"}`,
                                                className: "mt-5"
                                            }
                                        }}
                                        onClick={(e) => {
                                            setCenter([{
                                                lat: carregador.latitude,
                                                lng: carregador.longitude
                                            }])
                                            openSidebar(true, carregador.id_carregador)
                                            setZoom(zoom === 19 ? 19.1 : 19)
                                            //console.log('clicou no carregador:' + carregador.id_carregador)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </GoogleMap>
                </>
                : <></>}

        </>
    )
}
