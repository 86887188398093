import React from "react";

export default function InputData({
  setDataInicio,
  filterDate,
  dataAtual,
  minData,
  processDate,
}) {
  return (
    <input
      type="date"
      id="data_inicio"
      className="form-control mb-3"
      value={filterDate}
      max={dataAtual}
      //min={minData} // FIXME: precisa de data minima?
      onChange={(e) => {
        processDate(e.target.value);
      }}
    />
  );
}
