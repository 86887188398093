/* eslint-disable import/no-anonymous-default-export */
export default {
  formatarData: (data) => {
    var dataSplit = data.split("-");
    var ano = dataSplit[0];
    var mes = dataSplit[1];
    var dia = dataSplit[2];

    return dia + "." + mes + "." + ano;
  },

  pesquisa: (pesquisa, itens, setItens, setListaCompleta, setPaginaAtual) => {
    if (pesquisa === "") return;
    else {
      const result = itens.filter((item) => {
        //realizar pesquisa no id, nome de usuario e id usuario
        if (
          item.id_pagamento
            .toString()
            .toLowerCase()
            .includes(pesquisa.toLowerCase()) ||
          item.id_usuario
            .toString()
            .toLowerCase()
            .includes(pesquisa.toLowerCase()) ||
          item.usuario.toString().toLowerCase().includes(pesquisa.toLowerCase())
        )
          return item;
      });

      if (result.length === 0) {
        alert("Nenhum resultado encontrado");
        return;
      }
      setItens(result);
      setListaCompleta(result);
      setPaginaAtual(0);
    }
    //
  },
  filtros: (
    id_carregador,
    dtInicio,
    dtFinal,
    itens,
    setItens,
    setListaFiltrada,
    setFilterIdClick,
    setPaginaAtual
  ) => {
    if (id_carregador === "" && dtInicio === "" && dtFinal === "") return;

    if (dtInicio !== "" && dtFinal === "")
      return alert("Preencha a data final");

    if (dtInicio === "" && dtFinal !== "")
      return alert("Preencha a data inicial");

    if (dtInicio > dtFinal) return alert("Data inicial maior que data final");

    let result = [];

    if (id_carregador !== "") {
      result = itens.filter((item) => {
        if (
          id_carregador === "" ||
          item.id_carregador
            .toString()
            .toLowerCase()
            .includes(id_carregador.toLowerCase())
        )
          return item;
      });
    }

    if (dtInicio !== "" && dtFinal !== "") {
      const filtro = id_carregador === "" ? itens : result;
      result = filtro.filter((item) => {
        if (
          item.dt_cadastro.split(" ")[0] >= dtInicio &&
          item.dt_cadastro.split(" ")[0] <= dtFinal
        )
          return item;
      });
    }

    setItens(result);
    setListaFiltrada(result);
    setFilterIdClick(1);

    setPaginaAtual(0);
  },
};
