import React from 'react'
import MenuLateral from '../../../components/Menu-lateral'

export default function painelPagamentos() {
    return (
        <>
            <MenuLateral item5 />
        </>
    )
}
