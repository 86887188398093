/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Paginacao from "./../../Paginacao/paginacao";
import Pesquisa from "../../Paginacao/pesquisa";
import Swal from "sweetalert2";
import ButtonEdit from "../../Buttons/ButtonEdit";
import ControllerPage from "./../../ControllerPage/ControllerPage";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};

export default function ListarCarregadores(props) {
  const { data } = useSWR(`/v1/carregadores`, fetcher, {
    refreshInterval: 2500,
  });
  const [status, setStatus] = useState("T");
  const [tamanho, setTamanho] = useState(1000);
  const [pesquisa, setPesquisa] = useState("");
  const [tempo, setTempo] = useState(0);

  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const [paginas, setPaginas] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itensAtuais, setItensAtuais] = useState([]);
  const navigate = useNavigate();

  const filtro = "status";
  const local = "carregadores";
  const padding = "p-0";

  function handleEdit(id_carregador) {
    navigate(`/carregadores/${id_carregador}`);
  }
  function maskJs(value, pattern) {
    if (value) {
      let i = 0;
      const v = value.toString();
      return pattern.replace(/#/g, () => v[i++] || "");
    }
  }

  const handleSetPagina = (pagina) => {
    setPaginaAtual(pagina);
  };
  const handleSetItensPorPagina = (itens) => {
    setItensPorPagina(itens);
  };

  useEffect(() => {
    let tabela = document.getElementById("tabela").clientWidth;
    setTamanho(tabela);
    setItens(data);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let tabela = document.getElementById("tabela").clientWidth;
      if (tabela < 775) {
        setTamanho(tabela);
      }
    }, 400);
  }, [props.active]);

  // TODO: ATUALIZAR TABELAS EM TEMPO REAL SEM PERDER FILTROS DE PESQUISA
  // FIXME: FEITO -^
  useEffect(() => {
    if (pesquisa == "" && status == "T") {
      setItens(data);
    }
    if (pesquisa == "" && status !== "T") {
      setItens(data.filter((itens) => itens[filtro] === status));
    }
    if (pesquisa !== "" && status == "T") {
      api
        .get(`/v1/${local}/pesquisa/${pesquisa}`)
        .then((res) => {
          setItens(res.data);
        })
        .catch((error) => console.log(error));
    }
    if (pesquisa !== "" && status !== "T") {
      api
        .get(`/v1/${local}/pesquisa/${pesquisa}`)
        .then((res) => {
          setItens(res.data.filter((itens) => itens[filtro] === status));
        })
        .catch((error) => console.log(error));
    }
  }, [data]);

  // PAGINAÇÃO FIXME: talvez dê pra excluir algumas coisas daqui
  useEffect(() => {
    if (itens) {
      setPaginas(Math.ceil(itens?.length / itensPorPagina));
      setStartIndex(paginaAtual * itensPorPagina);
      setEndIndex(startIndex + itensPorPagina);

      if (pesquisa !== "") {
        if (tempo === 0) {
          //não ficar refazendo a requisição
        } else {
          if (status === "T") {
            api
              .get(`/v1/${local}/pesquisa/${pesquisa}`)
              .then((res) => {
                setItensAtuais(res.data.slice(startIndex, endIndex));
                setPaginaAtual(0);
                setTempo(0);
              })
              .catch((error) => console.log(error));
          } else {
            api
              .get(`/v1/${local}/pesquisa/${pesquisa}`)
              .then((res) => {
                setItensAtuais(
                  res.data
                    .filter((itens) => itens[filtro] === status)
                    .slice(startIndex, endIndex)
                );
                setPaginaAtual(0);
                setTempo(0);
              })
              .catch((error) => console.log(error));
          }
        }
      } else {
        if (status === "T") {
          setItensAtuais(data?.slice(startIndex, endIndex));
        } else {
          setItensAtuais(
            data
              ?.filter((itens) => itens[filtro] === status)
              .slice(startIndex, endIndex)
          );
        }
      }

      setItensAtuais(itens.slice(startIndex, endIndex)); // FIXME: talvez aqui eu deva tirar algum dia
    } else {
      setItens(data);
    }
  }, [data, status, endIndex, itens, itensPorPagina, paginaAtual, startIndex]);

  function handleIniciarCarga(id_carregador) {
    api
      .post(`v1/carregadores/starttransaction/${id_carregador}`)
      .then((res) => {
        if (res.status === 500) {
          Swal.fire({
            title: "Erro!",
            text: "Não foi possivel iniciar a carga.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }

        if (res.status === 200) {
          Swal.fire({
            title: "Sucesso!",
            text: "Carga iniciada com sucesso.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possivel iniciar a carga.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log(error);
      });
  }

  function handleFinalizarCarga(id_carregador) {
    api
      .post(`v1/carregadores/stoptransaction/${id_carregador}`)
      .then((res) => {
        if (res.status === 500) {
          Swal.fire({
            title: "Erro!",
            text: "Não foi possivel Finalizar a carga.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }

        if (res.status === 200) {
          Swal.fire({
            title: "Sucesso!",
            text: "Carga Finalizada com sucesso.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possivel finalizar a carga.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log(error);
      });
  }

  return (
    <div className="container-fluid w-100">
      <Box
        className={
          document.body.clientWidth < 775
            ? "d-block"
            : "d-flex justify-content-between w-100"
        }
        width={tamanho < 775 ? tamanho : "auto"}
      >
        <>
          <Box
            width={
              document.body.clientWidth < 775
                ? document.body.clientWidth
                : "w-100"
            }
          >
            <div className="d-flex align-content-center">
              <button
                className="btn btn-primary mb-3"
                onClick={(e) => navigate("/adicionar-carregador")}
              >
                Adicionar
              </button>
            </div>
          </Box>
        </>
        <>
          {document.body.clientWidth > 775 ? (
            <div className="mb-2 d-flex">
              <div className="d-flex me-1">
                <select
                  className="form-select me-3 align-self-center"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="T" defaultValue>
                    Todos
                  </option>
                  <option value="D">Disponível</option>
                  <option value="M">Manutenção</option>
                  <option value="U">Em uso</option>
                  <option value="P">Preparado</option>
                </select>
              </div>
              {/* pesquisa p/ pc */}
              <div className="d-flex me-1">
                <Pesquisa
                  filtro={filtro}
                  local={local}
                  parametro={status}
                  setPesquisa={setPesquisa}
                  setItens={setItens}
                  setPaginaAtual={setPaginaAtual}
                  PesquisaAtual={pesquisa}
                  tamanho={document.body.clientWidth}
                  setTempo={setTempo}
                  className=" align-self-center"
                  dataInicio=""
                  dataFim=""
                />
              </div>
            </div>
          ) : (
            <Box
              className="mb-2"
              width={
                document.body.clientWidth < 775
                  ? document.body.clientWidth
                  : "auto"
              }
            >
              <select
                className={`form-select mb-2 me-4 ${
                  document.body.clientWidth < 775 ? "w-px-200" : "w-100"
                }`}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="T" defaultValue>
                  {" "}
                  Todos
                </option>
                <option value="D">Disponível</option>
                <option value="M">Manutenção</option>
                <option value="U">Em uso</option>
                <option value="P">Preparado</option>
              </select>
              {/* pesquisa p/ celular */}
              <Pesquisa
                filtro={filtro}
                local={local}
                parametro={status}
                setPesquisa={setPesquisa}
                setItens={setItens}
                setPaginaAtual={setPaginaAtual}
                PesquisaAtual={pesquisa}
                tamanho={tamanho}
                setTempo={setTempo}
                dataInicio=""
                dataFim=""
              />
            </Box>
          )}
        </>
      </Box>
      <table
        className="table table-bordered text-center align-middle"
        id="tabela"
      >
        <thead>
          <tr>
            <th className={`w-5`}>Id</th>
            <th className={`w-15`}>Nome</th>
            <th className={`w-10`}>Plug</th>
            <th className={`w-5`}>Privado</th>
            <th className={`w-15`}>Cidade</th>
            <th className={`w-10`}>Cep</th>
            <th className={`w-5`}>Horario</th>
            <th className={`w-15`}>Telefone</th>
            <th className={`w-5`}>Ativo</th>
            <th className={`w-15`}>Status</th>
            <th className={`${padding}`}>Iniciar carga</th>
            <th className={`${padding}`}>Finalizar carga</th>
            <th className={`w-5`}>Editar</th>
          </tr>
        </thead>
        <tbody>
          {itensAtuais?.map((carregadores) => {
            return (
              <tr
                key={carregadores.id_carregador}
                // cor da linha
                className={`${
                  carregadores.status == "M"
                    ? "table-danger"
                    : carregadores.status == "U"
                    ? "table-success"
                    : carregadores.status == "P"
                    ? "table-warning"
                    : carregadores.status == "D"
                    ? "table-info"
                    : ""
                } table-hover `}
              >
                <th className={`${padding} text-sm`}>
                  {carregadores.id_carregador}
                </th>
                <td className={`${padding} text-sm`}>
                  {carregadores.nome_local}
                </td>
                <td className={`${padding}`}>{carregadores.plug}</td>
                <td className={`${padding}`}>
                  {carregadores.privado == "N" ? "Não" : ""}
                  {carregadores.privado == "S" ? "Sim" : ""}
                </td>
                <td className={`${padding}`}>{carregadores.cidade}</td>
                <td className={`${padding}`}>
                  {maskJs(carregadores.cep, "#####-###")}
                </td>
                {/* <td className={`${padding}`}>{carregadores.potencia}</td> */}
                <td className={`${padding}`}>{carregadores.horario}</td>
                <td className={`${padding}`}>{carregadores.telefone}</td>
                <td className={`${padding}`}>
                  {carregadores.ativo == "S" ? "Sim" : ""}
                  {carregadores.ativo == "N" ? "Não" : ""}
                </td>
                <td className={`${padding}`}>
                  {carregadores.status == "D" ? "Disponível" : ""}
                  {carregadores.status == "M" ? "Manutenção" : ""}
                  {carregadores.status == "U" ? "Em uso" : ""}
                  {carregadores.status == "P" ? "Preparado" : ""}
                </td>
                <td className={`${padding}`}>
                  {carregadores.status == "P" ? (
                    <button
                      className="btn btn-success text-center m-0-5"
                      disabled={carregadores.status == "P" ? false : true}
                      onClick={(e) => {
                        handleIniciarCarga(carregadores.id_carregador);
                      }}
                    >
                      Iniciar
                    </button>
                  ) : (
                    ""
                  )}
                </td>
                <td className={`${padding}`}>
                  {carregadores.status == "U" ? (
                    <button
                      className="btn btn-danger text-center m-0-5"
                      disabled={carregadores.status == "U" ? false : true}
                      onClick={(e) => {
                        handleFinalizarCarga(carregadores.id_carregador);
                      }}
                    >
                      Finalizar
                    </button>
                  ) : (
                    ""
                  )}
                </td>
                <td className={`${padding}`}>
                  <ButtonEdit
                    editar={carregadores.id_carregador}
                    handleEdit={handleEdit}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ControllerPage
        paginaAtual={paginaAtual}
        setPaginaAtual={handleSetPagina}
        paginas={paginas}
        itensPorPagina={itensPorPagina}
        setItensPorPagina={handleSetItensPorPagina}
      />
    </div>
  );
}
