import React, { useEffect, useState } from 'react'
import FormCodigo from '../../../components/Digitar-codigo/form-codigo'

export default function CadastroCodigo() {
  const url_string = window.location.href;
  const url = new URL(url_string);
  
  const [nome, setNome] = useState('')
  const [senha, setSenha] = useState('')
  const [telefone, setTelefone] = useState('')
  const email = localStorage.getItem('sessionEmail')

  useEffect(() => {
    setNome(url.searchParams.get("nome"))
    setSenha(url.searchParams.get("pas"))
    setTelefone(url.searchParams.get("tel"))
  }, [])

  return (
    <>
      <div className="fluid" id="form-digitar-codigo">
        <FormCodigo
          nome={nome}
          email={email}
          senha={senha}
          telefone={telefone}
        />
      </div>
    </>
  )
}
