import React, { useState } from 'react'
import { Button, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import Visivel from '../../assets/uteis/visivel.png'
import Invisivel from '../../assets/uteis/invisivel.png'

export default function InputSenha(props) {

    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');

    // Mostrar/sumir senha nova
    const [show, setShow] = React.useState(false)
    // Mostrar/sumir confirmação de senha
    const [show2, setShow2] = React.useState(false)

    const handleClick = () => setShow(!show)

    const handleClick2 = () => setShow2(!show2)

    return (
        <>
            <div className="mb-3">
                <label htmlFor="inputSenha">Senha (Mínimo 4 caracteres)</label>
                <InputGroup>
                    <Input
                        className="form-control"
                        onChange={(e) => setSenha(e.target.value)}
                        value={senha}
                        type={show ? 'text' : 'password'}
                        placeholder='Digite sua senha'
                        id="inputSenha"
                        aria-describedby="Senha"
                    />
                    <InputRightElement width='4.5rem' >
                        <Button size='sm' className="btn-visible mt-1" onClick={handleClick}>
                            {show ? <Image src={Visivel} width="100%" boxSize='30px' opacity={0.5} />
                                : <Image src={Invisivel} width="100%" boxSize='30px' opacity={0.5} />}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </div>

            <div className="mb-3">
                <label htmlFor="inputConfirmarSenha">Confirme a senha</label>
                <InputGroup>
                    <Input
                        className="form-control"
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                        value={confirmarSenha}
                        type={show2 ? 'text' : 'password'}
                        placeholder='Digite sua senha'
                        id="inputConfirmarSenha"
                        aria-describedby="Senha"
                    />
                    <InputRightElement width='4.5rem' >
                        <Button size='sm' className="btn-visible mt-1" onClick={handleClick2}>
                            {show2 ? <Image src={Visivel} width="100%" boxSize='30px' opacity={0.5} />
                                : <Image src={Invisivel} width="100%" boxSize='30px' opacity={0.5} />}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </div>

            <div className="d-flex justify-content-center mt-2">
                <button
                    type="button"
                    className="btn btn-primary btn-lg btn-principal"
                    onClick={(e) => props.HandleSenha(e, senha, confirmarSenha)}
                    disabled={props.loading}
                >
                    <span>Salvar</span>
                </button>
            </div>

            {props.msg.length > 0 ? <div className="alert alert-danger mt-2 text-center" role="alert"> {props.msg} </div> : null}
            {props.sucesso.length > 0 ? <div className="alert alert-success mt-2 text-center" role="alert"> {props.sucesso} </div> : null}
        </>
    )
}
