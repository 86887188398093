/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import useFunction from "./utils/useFunctions";
import { Image, Button, Box } from "@chakra-ui/react";
import { format } from "date-fns";
import ButonFilterIdCarregador from "./../../Buttons/ButonFilterIdCarregador";
import ControllerPage from "./../../ControllerPage/ControllerPage";
import pagamentosPDF from "./../../Reports/Pagamentos/pagamentos";
import IconPDF from "../../../assets/icones/tabelas/pdf.webp";
import InputFilterDate from "../../Inputs/InputFilterDate";
import InputLabel from "./../../Inputs/InputLabel";
import useGetAPI from "../../../hooks/useGetAPI";
import NewPesquisa from "../../Paginacao/newPesquisa";
import api from "../../../services/api";
import Swal from "sweetalert2";
import useSWR from "swr";
import "./styles.css";

const fetcher = async (url) => {
  const res = await api.get(url);
  return res.data;
};

export default function listarPagamentos(props) {
  const { data } = useSWR(`/v1/pagamentos`, fetcher, { refreshInterval: 2500 });
  const [usuarios, setUsuarios] = useState([]);
  const [carregamentos, setCarregamentos] = useState([]);

  const [status, setStatus] = useState("T");
  const [tamanho, setTamanho] = useState(1000);
  const [pesquisa, setPesquisa] = useState("");

  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const [paginas, setPaginas] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itensAtuais, setItensAtuais] = useState([]);
  const [listaCompleta, setListaCompleta] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);

  const [filterDateInicio, setFilterDateInicio] = useState("");
  const [filterDateFim, setFilterDateFim] = useState("");
  const [filterDate, setFilterDate] = useState(0);
  const [filterId, setFilterId] = useState("");
  const [filterIdClick, setFilterIdClick] = useState(0);

  const padding = "p-0";
  const ISOData = `${new Date().toISOString().split("T")[0]}`;

  const handleSetPagina = (pagina) => {
    setPaginaAtual(pagina);
  };
  const handleSetItensPorPagina = (itens) => {
    setItensPorPagina(itens);
  };

  const handlePesquisa = () => {
    //adicioanr nome de usuario nos itens
    let newItens = itens.map((items) => {
      const usuarioEncontrado = usuarios.find(
        (usuario) => usuario.id_usuario === items.id_usuario
      );
      if (usuarioEncontrado) {
        items.usuario = usuarioEncontrado.nome;
      }

      return items;
    });

    useFunction.pesquisa(
      pesquisa,
      newItens,
      setItens,
      setListaCompleta,
      setPaginaAtual
    );
  };
  const handleFiltros = () => {
    let newItens = itens.map((items) => {
      const carregamentoEncontrado = carregamentos.find(
        (carregamento) => carregamento.id_pagamento === items.id_pagamento
      );
      if (carregamentoEncontrado) {
        items.id_carregador = carregamentoEncontrado.id_carregador;
      }

      return items;
    });

    useFunction.filtros(
      filterId,
      filterDateInicio,
      filterDateFim,
      newItens,
      setItens,
      setListaFiltrada,
      setFilterIdClick,
      setPaginaAtual
    );
  };
  const handleApagar = () => {
    setPesquisa("");
    setStatus("T");
    setFilterDateInicio("");
    setFilterDateFim("");
    setFilterIdClick(0);
    setItens(data);
    setListaCompleta(data);
    setPaginaAtual(0);
  };

  const handleChangeStatus = (status) => {
    setStatus(status);
    if (status == "T") {
      if (listaCompleta.length == 0 && listaFiltrada.length == 0) {
        setItens(data);
      } else if (listaFiltrada.length > 0) {
        setItens(listaFiltrada);
      } else {
        setItens(listaCompleta);
      }

      setPaginaAtual(0);
      return;
    }

    let newLista = [];

    switch (true) {
      case listaCompleta.length == 0 && listaFiltrada.length == 0:
        newLista = data.filter((item) => item.status == `${status}`);
        break;
      case listaFiltrada.length > 0:
        newLista = listaFiltrada.filter((item) => item.status == `${status}`);
        break;
      default:
        newLista = itens.filter((item) => item.status == `${status}`);
        break;
    }

    setItens(newLista);
  };

  // TODO: FAZER MODAL PERGUNTANDO SOBRE TER CERTEZA QUE QUER EXTORNAR PAGAMENTO

  function handleExtornar(dt_cadastro, id_pagamento) {
    const dataAtual = new Date();
    const dataPassada = new Date(dt_cadastro);
    const diferenca = dataAtual - dataPassada; // Calcula a diferença entre dt_atual e a dt_passada em ms

    const limite = 3 * 60 * 60 * 1000; // Definir o limite de 3 horas em milissegundos

    // Verifica se a diferença é maior ou igual ao limite (3 horas)
    if (diferenca >= limite) {
      api
        .get(`/v1/pagamentos/estornar/${id_pagamento}`)
        .then((res) => {
          // A = APROVADO, C = CANCELADO, N = NEGADO, E = ERRO
          if (res.data === "A") {
            Swal.fire({
              title: "Sucesso!",
              text: "Pagamento Aprovado.",
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          if (res.data === "C") {
            Swal.fire({
              title: "Pagamento Cancelado!",
              text: "O pagamento foi Cancelado.",
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          if (res.data === "N") {
            Swal.fire({
              title: "Pagamento Negado!",
              text: "O pagamento foi negado.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          if (res.data === "E") {
            Swal.fire({
              title: "Erro ao estornar!",
              text: "Erro ao estornar pagamento.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: error,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } else {
      // 3 horas e 1 min (181) - diferença de minutos de quando foi criada ate o momento
      var diferencaMinutos = 181 - Math.round(diferenca / 60000);
      var diferencaHoras = Math.round(diferencaMinutos / 60);

      Swal.fire({
        title: "Erro!",
        text: `É necessário passar 3 horas após o pagamento para se fazer o estorno.
                \n Restam ${
                  diferencaHoras > 1
                    ? diferencaHoras + " horas"
                    : diferencaMinutos + " minutos"
                } `,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  function handleLimparID() {
    setItens(listaCompleta.length > 0 ? listaCompleta : data);
    setPaginaAtual(0);
    setItensAtuais(listaCompleta.slice(startIndex, endIndex));
    setPaginas(Math.ceil(listaCompleta.length / itensPorPagina));
    setFilterIdClick(0);
    setFilterId("");
    setFilterDateInicio("");
    setFilterDateFim("");
    setListaFiltrada([]);
  }

  function handleImprimirPDF() {
    let periodo = "";
    if (filterDateInicio && filterDateFim) {
      periodo =
        useFunction.formatarData(filterDateInicio) +
        " Até " +
        useFunction.formatarData(filterDateFim);
    } else {
      periodo = "Todos";
    }

    let filtro =
      status == "E"
        ? "Erro"
        : status == "A"
        ? "Aprovado"
        : status == "C"
        ? "Cancelado"
        : status == "N"
        ? "Negado"
        : "Todos";

    let soma = 0;
    let qtd = 0;
    let energia_total = 0;

    const ListaFinal = itens.map((pagamento) => {
      const usuarioEncontrado = usuarios.find(
        (usuario) => usuario.id_usuario === pagamento.id_usuario
      );
      if (usuarioEncontrado) {
        pagamento.usuario = usuarioEncontrado.nome;
      }

      const pagamentoEncontrado = carregamentos.find(
        (carregamento) => carregamento.id_pagamento === pagamento.id_pagamento
      );
      if (pagamentoEncontrado) {
        pagamento.energia_carregada = pagamentoEncontrado.energia_carregada;
        pagamento.unidade_energia = pagamentoEncontrado.unidade_energia;
      }
      return pagamento;
    });

    for (let x = 0; x < ListaFinal.length; x++) {
      soma += ListaFinal[x].valor;
      energia_total += Number(
        ListaFinal[x].energia_carregada ? ListaFinal[x].energia_carregada : 0
      );
      qtd = x + 1;
    }

    pagamentosPDF(ListaFinal, filtro, periodo, soma, qtd, energia_total);
  }

  // VER TAMANHO DA TABELA (RESPONSIVIDADE) E SETAR ITENS DE PAGINAÇÃO
  useEffect(() => {
    let tabela = document.getElementById("tabela").clientWidth;
    setTamanho(tabela);
  }, []);

  // TAMANHO DO BOTÃO
  useEffect(() => {
    setTimeout(() => {
      let tabela = document.getElementById("tabela").clientWidth;
      if (tabela < 775) {
        setTamanho(tabela);
      }
    }, 400);
  }, [props.active]);

  // PAGINAÇÃO
  useEffect(() => {
    if (itens) {
      setStartIndex(paginaAtual * itensPorPagina);
      setEndIndex(startIndex + itensPorPagina);

      setItensAtuais(itens.slice(startIndex, endIndex));
      setPaginas(Math.ceil(itens.length / itensPorPagina));
    }
  }, [
    data,
    itens,
    endIndex,
    itensPorPagina,
    paginaAtual,
    startIndex,
    filterDate,
  ]);

  useEffect(() => {
    setItens(data);
  }, [data]);

  useEffect(() => {
    setFilterDate(0);
  }, [filterDateInicio, filterDateFim]);

  useEffect(() => {
    console.log(itens);
  }, [itens]);

  useEffect(() => {
    useGetAPI.getUsersPagamento().then(setUsuarios);
    useGetAPI.getCarregamentosPagamento().then(setCarregamentos);
  }, []);

  return (
    <div className="container-fluid w-100">
      <Box
        className={
          tamanho < 775 ? "d-block" : "d-flex justify-content-between w-100"
        }
        width={tamanho < 775 ? tamanho : "auto"}
      >
        <div className="d-flex align-content-center me-1">
          <Button
            className="btn btn-danger align-self-center"
            variant="solid"
            onClick={(e) => {
              handleImprimirPDF(e);
            }}
          >
            <Image src={IconPDF} width={20} className="me-2" />
            Gerar pdf
          </Button>
        </div>

        {tamanho > 775 ? (
          <div className="d-flex">
            <InputFilterDate
              label="De"
              filterDate={filterDateInicio}
              setFilterDate={setFilterDateInicio}
              dataAtual={ISOData}
              processDate={setFilterDateInicio}
              minData={""}
            />
            <InputFilterDate
              label="Até"
              filterDate={filterDateFim}
              setFilterDate={setFilterDateInicio}
              dataAtual={ISOData}
              minData={""}
              setDataInicio={setFilterDateFim}
              processDate={setFilterDateFim}
            />

            <div className="d-flex align-items-cente me-1">
              <div className="d-flex justify-content-end">
                <div className="d-grid mb-4 flex-column">
                  <InputLabel
                    className="form-check-label align-self-start"
                    text={`Id carregador`}
                  />
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      min={0}
                      className="form-control w-50"
                      value={filterId}
                      onChange={(e) => setFilterId(e.target.value)}
                    />
                    <ButonFilterIdCarregador
                      filterId={filterIdClick}
                      handleFiltrarID={handleFiltros}
                      handleLimparID={handleLimparID}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-cente me-1">
              <select
                value={status}
                className="form-select h-50 align-self-center"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                <option value="T">Todos</option>
                <option value="A">Aprovado</option>
                <option value="C">Cancelado</option>
                <option value="N">Negado</option>
                <option value="E">Erro</option>
              </select>
            </div>
            <div className="d-flex">
              {/* pesquisa p/ pc */}
              <NewPesquisa
                handlePesquisar={handlePesquisa}
                setPesquisa={setPesquisa}
                className="h-50 align-self-center"
                handleApagar={handleApagar}
                PesquisaAtual={pesquisa}
              />
            </div>
          </div>
        ) : (
          <Box className="mb-2" width={tamanho < 775 ? tamanho : "auto"}>
            <div className="w-px-200">
              <InputFilterDate
                label="De"
                filterDate={filterDateInicio}
                setFilterDate={setFilterDateInicio}
                dataAtual={ISOData}
                processDate={setFilterDateInicio}
                minData={""}
                classInput="w-10"
              />
            </div>
            <div className="w-px-200">
              <InputFilterDate
                label="Até"
                filterDate={filterDateFim}
                setFilterDate={setFilterDateInicio}
                dataAtual={ISOData}
                minData={""}
                setDataInicio={setFilterDateFim}
                processDate={setFilterDateFim}
              />
            </div>

            <select
              className={`form-select mb-2 me-4 ${
                document.body.clientWidth < 775 ? "w-px-200" : "w-100"
              }`}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="T" defaultValue>
                Todos
              </option>
              <option value="A">Aprovado</option>
              <option value="C">Cancelado</option>
              <option value="N">Negado</option>
              <option value="E">Erro</option>
            </select>
            <div className="d-flex align-items-cente me-1">
              <div className="d-grid align-self-center mb-4">
                <InputLabel
                  className="form-check-label  align-self-center"
                  text={`Id carregador`}
                />
                <div className="d-flex">
                  <input
                    type="number"
                    className="form-control w-75"
                    value={filterId}
                    onChange={(e) => setFilterId(e.target.value)}
                  />
                  <ButonFilterIdCarregador
                    filterId={filterIdClick}
                    handleFiltrarID={handleFiltros}
                    handleLimparID={handleLimparID}
                  />
                </div>
              </div>
            </div>
            {/* pesquisa p/ celular */}
            <NewPesquisa
              handlePesquisar={handlePesquisa}
              setPesquisa={setPesquisa}
              className="h-50 align-self-center"
              handleApagar={handleApagar}
              PesquisaAtual={pesquisa}
            />
          </Box>
        )}
      </Box>
      <></>
      {/* <div className="table-responsive"> */}
      <table
        className="table table-bordered text-center align-middle"
        id="tabela"
      >
        <thead>
          <tr>
            <th className={`w-5`}>Id</th>
            <th className={`w-15`}>data de Inclusão</th>
            <th className={`w-5`}>Id Carregador</th>
            <th className={`w-5`}>Id usuário</th>
            <th className={`w-15`}>Usuário</th>
            <th className={`w-10`}>Valor</th>
            <th className={``}>Mensagem</th>
            <th className={`w-10`}>Status</th>
            <th className={`w-5`}>Tipo</th>
            <th className={``}>Extornar</th>
          </tr>
        </thead>
        <tbody>
          {itensAtuais?.map((pagamentos) => {
            return (
              <tr
                key={pagamentos.id_pagamento}
                // cor da linha
                className={`${
                  pagamentos.status == "E"
                    ? "table-danger"
                    : pagamentos.status == "A"
                    ? "table-success"
                    : pagamentos.status == "C"
                    ? "table-warning"
                    : pagamentos.status == "N"
                    ? "table-info"
                    : ""
                } table-hover`}
              >
                <th className={`${padding}`}>{pagamentos.id_pagamento}</th>
                <td className={`${padding}`}>
                  {format(
                    new Date(pagamentos.dt_cadastro),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                </td>
                <td className={`${padding}`}>
                  {carregamentos.map((carregamento) => {
                    if (carregamento.id_pagamento == pagamentos.id_pagamento) {
                      return carregamento.id_carregador;
                    }
                  })}
                </td>
                <td className={`${padding}`}>{pagamentos.id_usuario}</td>
                <td className={`${padding}`}>
                  {usuarios.map((usuarios) => {
                    if (usuarios.id_usuario == pagamentos.id_usuario) {
                      return usuarios.nome;
                    }
                  })}
                </td>
                <td className={`${padding}`}>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.valor)}
                </td>
                <td className={`${padding} text-sm`}>{pagamentos.mensagem}</td>

                <td className={`px-2`}>
                  {pagamentos.status == "E" ? "Erro" : ""}
                  {pagamentos.status == "A" ? "Aprovado" : ""}
                  {pagamentos.status == "C" ? "Cancelado" : ""}
                  {pagamentos.status == "N" ? "Negado" : ""}
                </td>
                <td className={`px-2`}>
                  {pagamentos.tipo == "C" ? "Carregamento" : ""}
                  {pagamentos.tipo == "V" ? "Validação" : ""}
                </td>
                <td className={`${padding}`}>
                  {pagamentos.status == "A" || pagamentos.status == "E" ? (
                    <button
                      className="btn btn-primary text-center mx-1"
                      disabled={
                        pagamentos.status == "A" || pagamentos.status == "E"
                          ? false
                          : true
                      }
                      onClick={(e) => {
                        handleExtornar(
                          pagamentos.dt_cadastro,
                          pagamentos.id_pagamento
                        );
                      }}
                    >
                      Estornar
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ControllerPage
        paginaAtual={paginaAtual}
        setPaginaAtual={handleSetPagina}
        paginas={paginas}
        itensPorPagina={itensPorPagina}
        setItensPorPagina={handleSetItensPorPagina}
      />
    </div>
  );
}
