import React, { useState } from 'react'
import { createContext } from "react";
import CryptoJS from 'crypto-js'

const UserContext = createContext({}, () => { });

function UserProvider(props) {

    //FIXME: pegando dados criptografados do localstorage(USADO NO EDITAR USUARIOS)
    const [tipo, setTipo] = useState(localStorage.getItem('sessionTipo') ? localStorage.getItem('sessionTipo') : '')
    const [idUsuario, setIdUsuario] = useState(localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : '')
    
    if(localStorage.getItem('uid') !== null){
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('uid'), "ID_ADM");
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
    }
    
    const [idNumber, setIdNumber] = useState(originalText?  originalText : '')

    return (
        <UserContext.Provider
            value={{
                tipo,
                setTipo,
                idUsuario,
                setIdUsuario,
                idNumber,
                setIdNumber
            }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }