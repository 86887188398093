/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import "./styles.css";
import api from "./../../services/api";

export default function pesquisa({
  local,
  filtro,
  parametro,
  setPesquisa,
  setItens,
  setTempo,
  setPaginaAtual,
  PesquisaAtual,
  tamanho,
  className,
  dataInicio,
  dataFim,
  itens,
}) {
  function handleApagar(e) {
    setPesquisa("");
    PesquisaAtual = "";
    handlePesquisar(e);
  }

  useEffect(() => {
    if (PesquisaAtual === "" && parametro === "T") {
      api
        .get(`/v1/${local}`)
        .then((res) => {
          if (dataInicio !== "" && dataFim !== "") {
            let itensFiltrado = res.data
              .filter((itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
            setItens(itensFiltrado);
          } else {
            setItens(res.data);
          }
        })
        .catch((error) => console.log(error));
    }
    if (PesquisaAtual === "" && parametro !== "T") {
      api
        .get(`/v1/${local}`)
        .then((res) => {
          if (dataInicio !== "" && dataFim !== "") {
            let itensFiltrado = res.data
              .filter((itens) => itens[filtro] === parametro)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
            setItens(itensFiltrado);
          } else {
            setItens(res.data.filter((itens) => itens[filtro] === parametro));
          }
        })
        .catch((error) => console.log(error));
    }

    if (PesquisaAtual !== "" && parametro === "T") {
      api
        .get(`/v1/${local}/pesquisa/${PesquisaAtual}`)
        .then((res) => {
          if (dataInicio !== "" && dataFim !== "") {
            let itensFiltrado = res.data
              .filter((itens) => itens[filtro] === parametro)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
            setItens(itensFiltrado);
          } else {
            setItens(res.data);
          }
        })
        .catch((error) => console.log(error));
    }

    if (PesquisaAtual !== "" && parametro !== "T") {
      api
        .get(`/v1/${local}/pesquisa/${PesquisaAtual}`)
        .then((res) => {
          if (dataInicio !== "" && dataFim !== "") {
            let itensFiltrado = res.data
              .filter((itens) => itens[filtro] === parametro)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio)
              .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
            setItens(itensFiltrado);
          } else {
            setItens(res.data.filter((itens) => itens[filtro] === parametro));
          }
        })
        .catch((error) => console.log(error));
    }
    setTempo(1);
    setPaginaAtual(0);
  }, [parametro]);

  function Teclas(e) {
    switch (e.code) {
      case "NumpadEnter":
      case "Enter":
        handlePesquisar();
        break;
      default:
        return null;
    }
  }

  function handlePesquisar(e) {
    if (e) {
      if (PesquisaAtual === "" && parametro === "T") {
        api
          .get(`/v1/${local}`)
          .then((res) => {
            setItens(res.data);
            setPaginaAtual(0);
          })
          .catch((error) => console.log(error));
      }
      if (PesquisaAtual === "" && parametro !== "T") {
        api
          .get(`/v1/${local}`)
          .then((res) => {
            setItens(res.data.filter((itens) => itens[filtro] === parametro));
            setPaginaAtual(0);
          })
          .catch((error) => console.log(error));
      }
      if (PesquisaAtual !== "" && parametro !== "T") {
        api
          .get(`/v1/${local}/pesquisa/${PesquisaAtual}`)
          .then((res) => {
            if (dataInicio && dataFim && dataInicio !== "" && dataFim !== "") {
              let itensFiltrado = res.data
                .filter((itens) => itens[filtro] === parametro)
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio
                )
                .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
              setItens(itensFiltrado);
            } else {
              setItens(res.data.filter((itens) => itens[filtro] === parametro));
              setPaginaAtual(0);
            }
          })
          .catch((error) => console.log(error));
      }
      if (PesquisaAtual !== "" && parametro === "T") {
        api
          .get(`/v1/${local}/pesquisa/${PesquisaAtual}`)
          .then((res) => {
            if (dataInicio && dataFim && dataInicio !== "" && dataFim !== "") {
              let itensFiltrado = res.data
                .filter(
                  (itens) => itens.dt_cadastro.split(" ")[0] >= dataInicio
                )
                .filter((itens) => itens.dt_cadastro.split(" ")[0] <= dataFim);
              setItens(itensFiltrado);
            } else {
              setItens(res.data);
              setPaginaAtual(0);
            }
          })
          .catch((error) => console.log(error));
      }
      setTempo(1);
    }
  }

  return (
    <div className={tamanho < 400 ? "d-block" : "d-flex"}>
      {/* deixar responsivo no celular */}
      <input
        type="text"
        className={`${className ? className : null} form-control w-auto`}
        value={PesquisaAtual}
        onChange={(e) => setPesquisa(e.target.value)}
        placeholder="Pesquisar"
        onKeyUp={(e) => Teclas(e)}
      />
      <button
        className="teste btn fw-bold"
        onClick={(e) => handleApagar(e)}
        title="Apagar pesquisa"
      >
        X
      </button>
      <button
        className={`${className ? className : null} btn btn-success`}
        onClick={(e) => handlePesquisar(e)}
      >
        Pesquisar
      </button>
    </div>
  );
}
