import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Itens from "./itens";
import Logo from "../../assets/logo/eletrix_branco_2.png";
import Trocar from "../../assets/uteis/menu-outline.svg";
import ListarUsuarios from "../Painel/usuarios";
import ListarCarregadores from "../Painel/carregadores";
import ListarCarregamentos from "../Painel/carregamentos";
import ListarPagamentos from "../Painel/pagamentos";
import Mapa from "../Painel/mapa";
import "./styles.css";
import { Box } from "@chakra-ui/react";
import CryptoJS from "crypto-js";

export default function Menu(props) {
  var bytes = CryptoJS.AES.decrypt(localStorage.getItem("uid"), "ID_ADM");
  var idNumber = bytes.toString(CryptoJS.enc.Utf8);

  // Verificar se o menu lateral está aberto ou não
  const [open, setOpen] = useState(
    localStorage.getItem("menuOpen") ? localStorage.getItem("menuOpen") : true
  );

  function handleMenu() {
    if (open === true || open === "true") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  useEffect(() => {
    localStorage.setItem("menuOpen", open);
  }, [open]);

  return (
    <div className="container-fluid row">
      {/* A classe "active" serve para saber se o menu está aberto ou fechado */}
      <div
        className={
          open === false || open === "false" ? "navegacao" : "navegacao active"
        }
      >
        <ul>
          <li>
            <Link to="/">
              <span className="icone">
                <img src={Logo} alt="" />
              </span>
              <span className="titulo">Eletrix 5</span>
            </Link>
          </li>
          {/* ITENS: qualquer item adicionado, add nova prop para ele */}
          <Itens
            active={open}
            item1={props.item1}
            item2={props.item2}
            item3={props.item3}
            item4={props.item4}
            item5={props.item5}
          />
        </ul>
        <div
          className={
            open === false || open === "false" ? "foot" : "foot-active"
          }
        >
          <div className="text">
            <p>{localStorage.getItem("sessionEmail") + `[${idNumber}]`}</p>
            <p className="version">V1.0</p>
          </div>
        </div>
      </div>

      <div
        className={open === false || open === "false" ? "main" : "main active"}
      >
        {/* Barra do topo*/}
        <div className="barra-topo">
          <div className="alternar">
            <img src={Trocar} alt="diminuir menu" onClick={handleMenu} />
          </div>
        </div>
        <Box
          className={
            open === false || open === "false" ? "cardbox" : "cardbox active"
          }
        >
          {/* TODO: FAZER COM O CHILDREN */}
          {/* ITENS: qualquer item adicionado, add nova renderização componentes */}
          {props.item1 ? <ListarCarregadores active={open} /> : null}
          {props.item2 ? <ListarUsuarios /> : null}
          {props.item3 ? <Mapa /> : null}
          {props.item4 ? <ListarCarregamentos /> : null}
          {props.item5 ? <ListarPagamentos /> : null}
        </Box>
      </div>
    </div>
  );
}
