import React from 'react'
import MenuLateral from '../../../components/Menu-lateral'


export default function PainelUsuarios() {
  return (
    <>
      <MenuLateral item2 />
    </>
  )
}
